import {
  Component, OnInit, AfterViewInit, ViewChild, ViewContainerRef, ComponentRef,
  ComponentFactoryResolver,
   ComponentFactory, 
   OnDestroy, Renderer2
} from '@angular/core';
import { CourseService } from './../../service/course.service';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { DataService } from './../../service/data.service';
import { CommonService } from './../../service/common.service';
import { ApiService } from './../../service/api.service';
import { QuizMultipleComponent } from '../../component/quiz-multiple/quiz-multiple.component';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { StudentTimeService } from './../../service/student-time.service';

@Component({
  selector: 'app-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.component.scss'],
})
export class CourseDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  courseDetailPost: any;
  courseSlug: any;
  sectionSlug: any;
  sectionId: any;
  postData: any;
  courseDetail: any;
  courseLangData:any;
  breadcrumbCourseName;
  userData;
  userId;
  startModuleSlug;

  lessonSlug: any = null;

  isLoading: boolean = true;
  courseimg: any;
  courseId2: any;

  startModuleBtnStatus: boolean = false;
  sitePath: any;
  getCourseDocuments: any;
  getInstituteUrl: any;

  courseSectionEndDate: any;
  courseQuizzes: any;

  quizPanelStatus: boolean = false;


  quizContainer: boolean = false;

  quizcmpdata: any;

  courseDescriptionWords: any;
  readMoreStatus: any;
  launchURL: any;
  quizIdSubject: any;
  quizSubjectData: any;
  charLimit: number = 270;
  showPanelStatus: false;

  showTitle: boolean = false;

  panelBgCol: any;
  panelTxtCol: any;

  seconds: number;

  /************************************** */
  @ViewChild("quizeDynamicComponent", { read: ViewContainerRef, static: true }) container;
  componentRef: ComponentRef<any>;
  siteDetail: any;
  timer: any;
  studentcourseDetailTime: any;
  timestamp: any;

  currentBtnThemeContainer: Subscription;
  buttonTheme: any;

  constructor(private courseService: CourseService, private router: Router, private route: ActivatedRoute,
    private dataService: DataService, public commonService: CommonService, private apiService: ApiService,
    private resolver: ComponentFactoryResolver, 
    private renderer: Renderer2, private sanitizer: DomSanitizer, private studentTimeService: StudentTimeService) {
      this.currentBtnThemeContainer = this.dataService.currentButtonTheme.subscribe(message => {
        this.buttonTheme = message;
      }, error => error);
    this.courseSlug = this.route.snapshot.paramMap.get('courseId')
    this.sectionSlug = this.route.snapshot.paramMap.get('sectionId')
    this.quizIdSubject = this.commonService.getQuizIdSubject.subscribe(
      data => {
        if (data['quiz_id']) {
          if (data['level_type'] == 1 && data['quiz_id']['quiz_reference_type'] != '7') {
            this.quizSubjectData = data['quiz_id'];
            this.getQuizStartById(this.quizSubjectData['id'], this.quizSubjectData['title'], this.quizSubjectData['instruction'], null, null, null, null, this.quizSubjectData.quiz_reference_type, this.quizSubjectData.gradebook_section_type)
          }
        }
      }
    )
  }

  toggleContainer(event) {
    event.target.parentElement.previousElementSibling.classList.toggle('open');
    const parentElm = event.target.parentElement.previousElementSibling;
    if (parentElm.className === 'course-description-container open') {
      event.target.innerText = 'Less';
      this.charLimit = 5000000;
    } else {
      event.target.innerText = 'Read More';
      this.charLimit = 270;
    }

  }

  getPanelVisuals() {
    const siteDetail = this.commonService.getCurrentSiteDetail();
    this.panelBgCol = siteDetail.theme_style.panel_back_color;
    this.panelTxtCol = siteDetail.theme_style.panel_text_color;
  }

  startTimer() {
    this.seconds = 0;
    this.timer = setInterval(() => {
      const value = Math.abs(this.seconds);
      const days = Math.floor(value / 1440);
      const hours = Math.floor((value - (days * 1440)) / 3600);
      const min = Math.floor((value - (days * 1440) - (hours * 3600)) / 60);
      const sec = value - (days * 1440) - (hours * 3600) - (min * 60);
      this.timestamp = `${this.seconds < 0 ? '-' : ''}${days > 0 ? days + '.' : ''}${hours < 10 ? '0' + hours : hours}:${min < 10 ? '0' + min : min}:${sec < 10 ? '0' + sec : sec}`
      this.seconds++;
    }, 1000);
  }




  ngOnInit() {
    this.startTimer();
    this.getPanelVisuals();
    this.getInstituteUrl = this.apiService.getApiSitePath();
    this.sitePath = this.apiService.getSiteFolderPath();
    this.getCourseDetail();
    const moduleListPostData = {
      id: this.commonService.getUserId(),
      method: 'getModuleListByCourseSlug',
      course_slug: this.courseSlug
    }

    this.courseService.getModuleListByCourse(moduleListPostData).subscribe(res => {
      if (!res['jwt_status']) {
      } else {
        if (res['data'] != undefined) {
          this.startModuleSlug = res['data'].slug;
          this.startModuleBtnStatus = true;
        }

      }
    })
  }
  ngAfterViewInit() {
    if (this.quizSubjectData) {
      if (this.quizSubjectData.quiz_reference_type != '7') {
        setTimeout(() => {
          this.getQuizStartById(this.quizSubjectData['id'], this.quizSubjectData['title'], this.quizSubjectData['instruction'], null, null, null, null, this.quizSubjectData.quiz_reference_type, this.quizSubjectData.gradebook_section_type)
        }, 1000)
      }
    }
  }
  /**
   * @description Toggle section
   * @date 2019-11-13
   * @method togglePanel
   * @memberof CourseDetailsComponent
   */
  togglePanel(togglePanelElement: HTMLElement, event) {
    let getElmH = togglePanelElement.children[0].clientHeight;
    if (this.showPanelStatus) {
      event.target.classList.toggle('active');
      togglePanelElement.classList.toggle('open');
      togglePanelElement.style.height = '0px';
      if (event.target.classList.contains('active')) {
        togglePanelElement.style.height = getElmH + 'px';
      } else {
        togglePanelElement.style.height = '0px';
      }
    } else {
      event.target.classList.toggle('active');
      togglePanelElement.classList.toggle('open');
      togglePanelElement.style.height = getElmH + 'px';
    }
  }

  /**
   * @description Reset toggle panel section
   * @date 2019-11-13
   * @method resetPanelCollapse
   * @memberof CourseDetailsComponent
   */
  resetPanelCollapse() {
    if (this.showPanelStatus) {
      setTimeout(() => {
        const getHeaderPan = document.querySelectorAll('.pc-header.active');
        const getContentPan = document.querySelectorAll('.pc-content.open');
        for (let i = 0; i < getHeaderPan.length; i++) {
          this.renderer.removeClass(getHeaderPan[i], 'active');

          if (getContentPan[i]) {
            this.renderer.removeClass(getContentPan[i], 'open');
            this.renderer.setStyle(getContentPan[i], 'height', '0');
          }
        }
      }, 200)
    } else {
      setTimeout(() => {
        const getHeaderPan = document.querySelectorAll('.pc-header');
        const getContentPan = document.querySelectorAll('.pc-content');
        for (let i = 0; i < getHeaderPan.length; i++) {
          this.renderer.addClass(getHeaderPan[i], 'active');
          this.renderer.addClass(getContentPan[i], 'open');
          this.renderer.setStyle(getContentPan[i], 'height', 'auto');
        }
      }, 200)
    }
  }

  /**
   * @description get course details by course / course-section slug
   * @date 2019-09-16
   * @memberof CourseDetailsComponent
   */
  getCourseDetail() {
    this.postData = {
      id: this.commonService.getUserId(),
      courseSlug: this.courseSlug,
      sectionSlug: this.sectionSlug,
      user_id: this.commonService.getUserId(),
      lang_id: localStorage.getItem('locale') == 'ger' ? 1 : 2,
      method: 'courseDetailsBySlugs'
    }

    this.courseService.getCourseDetail(this.postData).subscribe(res => {
      if (!res['jwt_status']) {
        this.commonService.openSessionExpireDialog();
        return
      } else {
        if (res['status']) {
          this.courseDetail = res['data'];
          console.log(this.courseDetail.description);
          
          this.courseLangData = res['data'].course_lang_data;

          // if(localStorage.getItem('locale') == 'ger') {
          //   this.courseDetail.name = this.courseLangData[0].name
          //   this.courseDetail.introduction  = this.courseLangData[0].introduction
          //   this.courseDetail.description  = this.courseLangData[0].description
          //   this.courseDetail.getting_started  = this.courseLangData[0].getting_started
          // } else {
          //   if(this.courseLangData[1]) {

          //   this.courseDetail.name = this.courseLangData[1].name
          //   this.courseDetail.introduction  = this.courseLangData[1].introduction
          //   this.courseDetail.description  = this.courseLangData[1].description
          //   this.courseDetail.getting_started  = this.courseLangData[1].getting_started

          //   } else {
          //     this.courseDetail.name = this.courseLangData[0].name
          //   this.courseDetail.introduction  = this.courseLangData[0].introduction
          //   this.courseDetail.description  = this.courseLangData[0].description
          //   this.courseDetail.getting_started  = this.courseLangData[0].getting_started
          //   }
          // }

          if (this.courseDetail != undefined) {
            this.resetPanelCollapse();

            const newArray = res['data'].quiz_data.filter(item => item.is_cert_quiz == 0);

            if (res['data'].quiz_data.length > 0) {
              if (newArray.length > 0) {
                this.quizPanelStatus = true;
                this.courseQuizzes = newArray;
              }
            } else {
              this.quizPanelStatus = false;
            }
            this.getCourseDocuments = res['data']['documents'];
            this.sectionId = this.courseDetail.section_id;
            this.courseimg = this.courseDetail.course_img != 'undefined' ? this.courseDetail.course_img : null;
            this.isLoading = false;
          }
          if (this.componentRef != undefined) {
            this.componentRef.destroy();
          }
        } else {
          this.commonService.redirectToLogin();
        }

      }
    }, error => error);
  }
  /**************************************/
  /****  Create Dynamic Component   */

  getQuizStartById(quizeId: any, quizTitle: string, quizItemInstruction: any, isCompleted: null, attempts?: null, view_btn?: null, quiz_time?: null, quiz_ref_type?: any, gradebook_section_type?: any) {
    const course_slug_id = this.route.snapshot.paramMap.get('courseId')
    // tslint:disable-next-line: variable-name
    const section_slug_id = this.route.snapshot.paramMap.get('sectionId')
    this.quizcmpdata = quizeId;
    const quizDetails = {
      id: quizeId,
      name: quizTitle,
      instruction: quizItemInstruction,
      course_slug: course_slug_id,
      section_slug: section_slug_id,
      is_completed: isCompleted,
      allow_attempts: attempts,
      view_result_btn: view_btn,
      quiz_time: quiz_time,
      quiz_reference_type: quiz_ref_type,
      gradebook_section_type: gradebook_section_type,
      pageType: 1
    }
    if (this.container) {
      this.container.clear();
      const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(QuizMultipleComponent);

      this.componentRef = this.container.createComponent(factory);
      this.componentRef.instance.type = quizDetails;
      this.componentRef.instance.output.subscribe(event => {
        if (event.action_type == 1) {
          document.getElementById(event.quizId).classList.add('is-complete');
          const element = document.getElementById(event.quizId) as HTMLElement;
          const elementCourse = document.getElementById('quiz_course_' + event.quizId) as HTMLElement;
          this.renderer.addClass(elementCourse, 'active');
        }
        if (event.action_type == 2) {
          this.getQuizStartById(event.quizeId, event.quizTitle, event.instruction, null);
        }
        this.quizIdSubject.unsubscribe();
      });
    }
  }

  startModule(){
     const data = {
      id: this.commonService.getUserId(),
      method: 'savingStudentTime',
      container_id: this.commonService.getDomainContainerId(),
      course_slug: this.courseSlug,
      section_slug: this.sectionSlug,
      course_time: this.timestamp,
      page: 'C'
    };

    this.studentTimeService.saveStudentTime(data).subscribe(res => {

    });

    clearInterval(this.timer);
    this.commonService.routeToModuleDetail(this.courseSlug , this.sectionSlug ,  this.startModuleSlug , this.lessonSlug)
  }

  ngOnDestroy() {
    this.currentBtnThemeContainer.unsubscribe();
  }
}
