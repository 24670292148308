import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from './../../service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SiteDomainService } from './../../service/sitedomain.service';
import { CommonService } from './../../service/common.service';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from './../../service/data.service';
import { Subscription } from 'rxjs';
import { UserService } from './../../service/user.service';
import { HeaderService } from './../../service/header.service';

export interface dataStyle {
	id: any;
	name: any;
};

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
	currentBtnThemeContainer: Subscription;
	buttonTheme: any;
	headerUsername: string;
	showRegisterLink: any;
	isLoadingRegister: boolean = true;
	domainURL: any;
	wrongUsernamePasswordErr: boolean = false;
	notAllowedLoginErr: boolean = false;
	fillAllFormErr: boolean = false;
	constructor(private authService: AuthService, private formBuilder: FormBuilder,
		private router: Router, private route: ActivatedRoute, private domain: SiteDomainService,
		private commonService: CommonService, public translate: TranslateService, private dataService: DataService, private userService: UserService, private headerService: HeaderService) {
		this.domainURL = this.domain.getDomainName();
		this.currentBtnThemeContainer = this.dataService.currentButtonTheme.subscribe(message => {
			this.buttonTheme = message;
		}, error => error);
	}
	loginForm: FormGroup;
	email: FormControl;
	method: string;
	password: FormControl;
	public message: string;
	submitted = false;
	isLoading: boolean = false;
	user: any;
	lang : any ; 
	containerId: any;
	siteDomain : any ; 
	isPresent: any;
	roleIdRes: any;
	allowedUserRoles = [
		{ id: 11, name: 'Student' },
		// { id: 17, name: 'Host' }
	];

	language : string ; 
  

	ngOnDestroy() {
		this.currentBtnThemeContainer.unsubscribe();
	}

	getSiteDetail(segmentName) {
		this.headerService.getSiteDetail(segmentName).subscribe(res => {
			if (!res['status']) {
				this.router.navigate(['/not-found']);
			} else {
				this.dataService.changeShowRegisterLink(res['data'].is_registration);
				this.showRegisterLink = (res['data'].is_registration == 1) ? true : false;
				this.isLoadingRegister = false;
			}
		})
	}

	ngOnInit() {
		this.language = localStorage.getItem('locale')
		this.getSiteDetail(this.domainURL);
		this.containerId = this.commonService.getDomainContainerId()
		const themeStyleString = localStorage.getItem('theme_style');
		const themeStyleObject = JSON.parse(themeStyleString);
		// setTimeout(() => {
		// 	this.siteDomain = themeStyleObject.domain;
		// 	// localStorage.setItem('domain',this.siteDomain)
		// }, 1000);
				

		this.user = JSON.parse(localStorage.getItem('loggedUser'))
		if (localStorage.getItem('locale') === 'ger') {
			this.lang = 'de'
		} else {
			this.lang = 'en'
		}

		if (this.user) {
			this.getUserRoleWiseRoutes(this.user.role_id)
		}

		this.email = this.formBuilder.control('', [Validators.required])
		this.password = this.formBuilder.control('', Validators.required)

		this.loginForm = this.formBuilder.group({
			username: this.email,
			password: this.password,
			method: 'Login',
			language: this.lang,
			domain : localStorage.getItem('domain')
		});

	}

	getUserRoleWiseRoutes(role_id) {
		switch (role_id) {
			case this.commonService.userRolesArr['Student']:
				this.router.navigate(['/dashboard'], { relativeTo: this.route })
				break;
			case this.commonService.userRolesArr['Host']:
				this.router.navigate(['/create-room'])
				break;
		}
	}

	// onloadCallback = function () {
	// 	grecaptcha.render('google_recaptcha', {

	// 	});

	// };

	// setCookie(name, value, daysToExpire) {
	// 	const date = new Date();
	// 	date.setTime(date.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));
	// 	const expires = "expires=" + date.toUTCString();
	// 	const domain = ";domain=.retteracademy.com";
	// 	document.cookie = name + "=" + value + ";" + expires + ";path=/" + domain;
	//   }


	setCookie(name, value, days) {
		let expires = "";
		if (days) {
			let date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			//   date.setTime(date.getTime() + 60000);
			expires = "; expires=" + date.toUTCString();
		}
		const domain = ";domain=.retteracademy.com";
		document.cookie = name + "=" + (value || "") + expires + "; path=/" + domain;
	}

	/**
	 * @description : Login User API
	 * @date 2019-09-16
	 * @param {*} event
	 * @memberof LoginComponent
	 */
	loginUser(event) {
		if (this.loginForm.status == 'VALID') {
			event.preventDefault();
			this.wrongUsernamePasswordErr = false;
			this.notAllowedLoginErr = false;
			this.fillAllFormErr = false;
			this.submitted = true;
			this.isLoading = true;
			this.message = ''
			this.authService.login(this.loginForm.value).subscribe(res => {
				this.isLoading = false;
				if (res['status']) {
					this.roleIdRes = res['data'].role_id
					const roleId = this.roleIdRes
					const presentRoleArr = this.allowedUserRoles.filter(
						item => {
							item.id == roleId ? this.isPresent = true : this.isPresent = false;
							return this.isPresent;
						}
					)

					// Set User Policy Status param in local storage
					if (res['data']['privacy_policy_tracking'] == 1) {
						localStorage.setItem("user_policy", "true")
					} else if (res['data']['privacy_policy_tracking'] == 0) {
						localStorage.setItem("user_policy", "false")
					}
					if (presentRoleArr.length) {
						localStorage.setItem('user_img', res['data'].profile_image_url);
						localStorage.setItem('headerFullName', res['data'].first_name + ' ' + res['data'].last_name);
						localStorage.setItem('loggedUser', JSON.stringify(res['data']));


						// if ((res['data'].user_lang == 1) || (res['data'].user_lang == 0)) {
						// 	localStorage.setItem('locale', 'ger');
						// 	this.dataService.changeAppLag('ger');
						// } else {
						// 	localStorage.setItem('locale', 'en');
						// 	this.dataService.changeAppLag('en');
						// }

						localStorage.setItem('jwt', res['token'])
						localStorage.setItem('rw_jwt', res['data'].rw_auth_token)
						this.setCookie("rw_jwt", res['data'].rw_auth_token, 365);
						this.authService.setLoggenIn(true)
						this.getUserRoleWiseRoutes(roleId)
					} else {
						//this.message = 'You are not allowed to login.';
						this.notAllowedLoginErr = true;
					}

				} else {
					//this.message = 'Wrong Username and password';
					this.wrongUsernamePasswordErr = true;
				}
			}, error => error);

		} else {
			//this.message = 'Please fill all fields';
			this.fillAllFormErr = true;
		}
	}

	/**
	 * @description : Route to Forgot Password Page
	 * @date 2019-09-16
	 * @memberof LoginComponent
	 */
	routeToForgotPassword() {
		this.router.navigate(['/forgot-password'], { relativeTo: this.route })
	}

	redirectToRegister() {
		this.router.navigate(['user-registration']);
	}

}
