import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';
import { CategoryListVideoComponent } from './component/video/category-list-video/category-list-video.component';
// tslint:disable-next-line: indent
// tslint:disable-next-line: max-line-length
import {
	NewpasswordComponent, MainComponent, DashboardComponent, FrontComponent, LoginComponent,
	ForgotPasswordComponent, NotFoundComponent, UserMessageComponent, CourseDetailsComponent, CourseComponent,
	CourseLayoutComponent, ModuleDetailComponent, LessonDetailComponent, MessageListComponent,
	NotificationListComponent, MessageDetailComponent, NotificationDetailComponent,
	ProfileComponent, EditProfileComponent, GradeBookComponent,
	// tslint:disable-next-line: max-line-length
	GradebookStudentQuizResultComponent, PrivacyPolicyComponent, ChangeUserPasswordComponent, ModuleOutcomeComponent, CourseOutcomeComponent, ContactUsComponent, ImprintComponent, DataProtectionComponent, VideoCategoryComponent, VideoDetailComponent , MarketplaceCourseListComponent , PurchaseHistoryComponent, 
         CategoryDashboardComponent , ViewAllCatCoursesComponent , CourseDetailsPreviewComponent , PreviewLayoutComponent , ModuleDetailsPreviewComponent , LessonDetailsPreviewComponent, 
        UserRegistrationComponent, HostRoomComponent ,  UserRoomComponent , RoomExpireComponent ,ActiveRoomDetailsComponent , StreamMainComponent ,ChatHistoryComponent , StudentFeedbackComponent,CreateFeedbackComponent , WikiSystemComponent, WikiArticleListComponent , WikiArticleDetailComponent, GeneralChatComponent
} from './index';
import { SocketReconnectComponent } from './testing/socket-reconnect/socket-reconnect.component';
import { UserRoomSectionComponent } from './component/user-room-section/user-room-section.component'
import { LeaveStreamConfirmGuard } from './guard/leave-stream-confirm.guard';
import { HostRoomLeaveGuard } from './guard/host-room-leave.guard';
import { UserRoomTwoComponent } from './component/user-room-two/user-room-two.component';
import { HostRoomTwoComponent } from './stream/component/host-room-two/host-room-two.component';
import { UserRoomSectionTwoComponent } from './component/user-room-section-two/user-room-section-two.component';
import { ScreenShareComponent } from './component/screen-share/screen-share.component';
import { WikiLayoutComponent } from './component/wiki/layout/wiki-layout/wiki-layout.component';

import { CourseChatComponent } from './live-chat/component/course-chat/course-chat.component';
import { SwitchInstituteComponent } from './component/switch-institute/switch-institute.component';
import { CourseCertificateComponent } from './component/course-certificate/course-certificate.component';




const routes: Routes = [
	{
		path: '',
		component: FrontComponent,
		children: [
			{
				path: '',
				redirectTo: 'login',
				pathMatch: 'full'
			},
			{
				path: 'login',
				component: LoginComponent
			},
			{
				path:'user-registration',
				component:UserRegistrationComponent
			},
			{
				path: 'contact-us',
				component: ContactUsComponent
			},
			{
				path: 'imprint',
				component: ImprintComponent
			},
			{
				path: 'data-protection',
				component: DataProtectionComponent
			},
			{
				path: 'forgot-password',
				component: ForgotPasswordComponent
			},
			{
				path: 'newpassword/:forgotCode',
				component: NewpasswordComponent
			},
			
			{
				path: 'user-message',
				component: UserMessageComponent
			},
			{
				path: 'user-course-list',
				component: CourseComponent
			},	
			{
				path: 'not-found',
				component: NotFoundComponent
			},
			{
				path: 'privacy-policy',
				component: PrivacyPolicyComponent
			},
			{
				path : 'switch-institute',
				component: SwitchInstituteComponent
			}
		]
	},
	{
		path: '',
		component: MainComponent,
		canActivate: [AuthGuard],
		children: [
            {
				path:'chat-history',
				component:ChatHistoryComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'dashboard',
				component: CategoryDashboardComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'category-dashboard',
				component: CategoryDashboardComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'message-list',
				component: MessageListComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'notification-list',
				component: NotificationListComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'message-detail/:messageId',
				component: MessageDetailComponent,
				canActivate: [AuthGuard],
			},
			{
				path: 'notification-detail',
				component: NotificationDetailComponent,
				canActivate: [AuthGuard],
			},
			{
				path: 'profile',
				component: ProfileComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'certificates',
				component: CourseCertificateComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'edit-profile',
				component: EditProfileComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'change-user-password',
				component: ChangeUserPasswordComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'view-all-category-courses/:categoryId',
				component: ViewAllCatCoursesComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'video-category',
				component: VideoCategoryComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'video-category/:categoryId',
				component: CategoryListVideoComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'video-detail/:videoId',
				component: VideoDetailComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'maket-place-course-list',
				component: MarketplaceCourseListComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'purchase-history',
				component: PurchaseHistoryComponent,
				canActivate: [AuthGuard]
			},
			{
				path:'user-stream-room-data',
				component:ActiveRoomDetailsComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'join-room/:roomId',
				component: UserRoomComponent,
				canActivate: [AuthGuard],
				canDeactivate:[LeaveStreamConfirmGuard]
			},
			{
				path: 'join-room-two/:roomId',
				component: UserRoomTwoComponent,
				canActivate: [AuthGuard],
				//canDeactivate:[LeaveStreamConfirmGuard]
			},
			{
				path:'test',
				component:SocketReconnectComponent,
				canActivate: [AuthGuard]
			},
			{
				path:'user-room-section',
				component:UserRoomSectionComponent,
				canActivate: [AuthGuard]
			},
			{
				path:'user-room-section-two',
				component:UserRoomSectionTwoComponent,
				canActivate: [AuthGuard]
			},
			{
				path:'room-expired',
				component:RoomExpireComponent,
				canActivate: [AuthGuard]
			},
			{
				path:'screen-share/:val',
				component:ScreenShareComponent,
				canActivate: [AuthGuard]
			},
			{
				path:'student-feedback',
				component:StudentFeedbackComponent,
				canActivate: [AuthGuard]
			},
			{
				path:'create-feedback',
				component:CreateFeedbackComponent,
				canActivate: [AuthGuard]
			},
			{
				path:'wiki-system',
				component:WikiSystemComponent,
				canActivate: [AuthGuard]
			},
			{
				path:'wiki-article-list/:catId',
				component:WikiArticleListComponent,
				canActivate: [AuthGuard]
			},
			{
				path:'wiki-article-list/detail/:catId',
				component:WikiArticleListComponent,
				canActivate: [AuthGuard]
			},
			{
				path:'general-chat',
				component:GeneralChatComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'not-found',
				component: NotFoundComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'privacy-policy',
				component: PrivacyPolicyComponent,
				canActivate: [AuthGuard]
			}

		]
	},
	{
		path: '',
		component: StreamMainComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'notification-list',
				component: NotificationListComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'not-found',
				component: NotFoundComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'privacy-policy',
				component: PrivacyPolicyComponent,
				canActivate: [AuthGuard]
			}
		]
	},

	{
		path: '',
		component: CourseLayoutComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'courses/:courseId/:sectionId',
				component: CourseDetailsComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'course-outcome/:courseId/:sectionId',
				component: CourseOutcomeComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'gradbook/:courseId/:sectionId',
				component: GradeBookComponent,
				canActivate: [AuthGuard]
			},

			{
				path: 'module-outcome/:courseId/:sectionId/:moduleId',
				component: ModuleOutcomeComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'courses/:courseId/:sectionId/:moduleId',
				component: ModuleDetailComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'courses/:courseId/:sectionId/:quizId/:studentId/gradebookquizresult',
				component: GradebookStudentQuizResultComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'courses/:courseId/:sectionId/:moduleId/:lessonId',
				component: LessonDetailComponent,
				canActivate: [AuthGuard]
			},
			{
				path:'course-chat/:courseId/:sectionId',
				component:CourseChatComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'not-found',
				component: NotFoundComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'privacy-policy',
				component: PrivacyPolicyComponent,
				canActivate: [AuthGuard]
			}

		]
	},

	{
		path: '',
		component: PreviewLayoutComponent,
		children: [
			{
				path: 'course-detail-preview/:courseId',
				component: CourseDetailsPreviewComponent
			},
			{
			path: 'module-detail-preview/:moduleId',
			component: ModuleDetailsPreviewComponent
			},
			{
				path: 'lesson-detail-preview/:lessonId',
				component: LessonDetailsPreviewComponent
			},
			{
				path: 'not-found',
				component: NotFoundComponent
			},
			{
				path: 'privacy-policy',
				component: PrivacyPolicyComponent
			}

		]
	},
	{
		path:'',
		component:WikiLayoutComponent,
		children:[
			
			{
				path:'wiki-article-detail/:articleId',
				component:WikiArticleDetailComponent
			},
			{
				path: 'not-found',
				component: NotFoundComponent
			},
			{
				path: 'privacy-policy',
				component: PrivacyPolicyComponent
			}
		]
	},

	{
		path: 'privacy-policy',
		component: PrivacyPolicyComponent
	},
	{
		path: '**',
		redirectTo: 'not-found'
	},
	{
		path: 'not-found',
		component: NotFoundComponent
	},
];


@NgModule({
	imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
