import { Component, OnInit, AfterViewInit, Renderer2, Input, Output, EventEmitter } from "@angular/core";
import { CourseService } from "./../../service/course.service";
import { NavigationStart, Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { CommonService } from "./../../service/common.service";
import { BehaviorSubject, Subject, Subscription, takeUntil } from "rxjs";
// import { DataService } from "src/app/service/data.service";
import { DataService } from "../../service/data.service";
// import { LiveChatService } from "src/app/live-chat/service/live-chat.service";
import { LiveChatService } from "../../live-chat/service/live-chat.service";
import { MatDialog } from "@angular/material/dialog";
// import { UserChatTermsComponent } from "src/app/dialogBox/user-chat-terms/user-chat-terms.component";
import { UserChatTermsComponent } from "../../dialogBox/user-chat-terms/user-chat-terms.component";
import { WikiService } from "src/app/component/wiki/service/wiki.service";
import { VideoService } from "src/app/service/video.service";
import { ChatService } from "src/app/live-chat/service/chat.service";
import { DashboardService } from "src/app/service/dashboard.service";
import { data, event } from "jquery";

@Component({
  selector: "app-aside",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit, AfterViewInit {
  @Output() categorySelected: EventEmitter<string> = new EventEmitter<string>();


  disableMenu: any = false;
  generalChatContainer: Subscription;
  showChatSidebarMenu: Boolean = false;
  showChatMenu: Boolean = false;
  generalUserChatTermsContainer: Subscription;
  dialogRef: any;
  wiki_notfound: boolean;
  media_notfound: boolean;
  chat_notfound: boolean;
  chatRoomId: any;
  roomName: string;
  historyChatCompoent$: Subject<void> = new Subject();
  show_sidebar: boolean = true
  isLoading = false;
  notfound = false;
  courseDataContainer: any;
  uncategoryCourseDataContainer: any;
  userData: any;
  userId: any;
  userRole: any;
  dashboradRoute: any;
  studentRole: any;
  hostRole: any;
  hostRoomUrl: String = "/create-room-two";
  activeRoomUrl: String = "/user-room-section";
  isDashboardActive: boolean = false;
  isWikiActive: boolean = false;
  isMediaActive: boolean = false;
  isMediaSecondParam: boolean = false;
  selectedCategory: any;
  selectedWikiCategory: any;
  selectedMediaCategory: any;
  wikiCategory: any;
  mediaCategory: any;
  isData: boolean = false;
  isWikiData: boolean = false;
  isMediaData: boolean = false;

  constructor(
    private courseService: CourseService,
    private router: Router,
    private renderer: Renderer2,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private dataService: DataService,
    private liveChatService: LiveChatService,
    private dialog: MatDialog,
    private wikiService: WikiService,
    private videoService: VideoService,
    private chatService: ChatService,
    private dashboardService: DashboardService
  ) {
    this.disableMenu = this.router.routerState.snapshot.url.split("/")[1] === "general-chat" ? false : true;
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.disableMenu = event.url.split("/")[1] === "general-chat" ? false : true;
      }
    });

    this.isDashboardActive = router.routerState.snapshot.url.split('/')[1] === 'dashboard' ? true : false;
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.isDashboardActive = event.url.split('/')[1] === 'dashboard' ? true : false;
        if (this.isDashboardActive) {
          this.selectCategory('all')
        }
      }
    })

    // this.isWikiActive = router.routerState.snapshot.url.split('/')[1] === 'wiki-system' ? true : false;

    // console.log(this.isWikiActive);

    // router.events.forEach((event) => {
    //   if (event instanceof NavigationStart) {
    //     this.isWikiActive = event.url.split('/')[1] === 'wiki-system' ? true : false
    //     if(this.isWikiActive){
    //       this.selectWikiCategory('all')
    //     }
    //   }
    // })

    this.checkWikiRoute(this.router.routerState.snapshot.url);

    // Subscribe to router events
    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.checkWikiRoute(event.url);
      }
    });


    this.isMediaActive = router.routerState.snapshot.url.split('/')[1] === 'video-category' ? true : false;
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.isMediaActive = event.url.split('/')[1] === 'video-category' ? true : false;
        this.isMediaSecondParam = event.url.split('/')[2] ? true : false;
        if (!this.isMediaSecondParam) {
          this.selectMediaCategory('all')
        }
      }
    })

    this.generalUserChatTermsContainer =
      this.dataService.currentUserGeneralChatTerms.subscribe((res) => {
        this.showChatMenu = res
      });

    this.generalChatContainer = this.dataService.currentGeneralChatId.subscribe(
      (message) => {
        this.showChatSidebarMenu = message;
      }
    );
  }


  selectCategory(category) {
    this.selectedCategory = category.category_id
    this.dataService.courseCategory(category);
  }

  selectWikiCategory(category) {
    this.selectedWikiCategory = category.id
    this.dataService.wikiCategory(category)
  }

  selectMediaCategory(category) {
    this.selectedMediaCategory = category.id
    this.dataService.mediaCategory(category)
  }

  selectAllMediaCategory() {
    //call subject
    this.selectedMediaCategory = undefined;
    this.dataService.mediaCategory('gotoback');
  }

  selectAllWikiCategory() {
    //call subject
    this.dataService.wikiCategory('gotoback')
  }

  checkWikiRoute(url: string): void {
    const segments = url.split('/');
    const firstSegment = segments[1];

    // Check if the URL is exactly '/wiki-article-list/detail'
    if (url === '/wiki-article-list/detail' || url.startsWith('/wiki-article-list/detail/')) {
      this.isWikiActive = false;
    } else {
      // Check if the first segment is one of the desired routes
      this.isWikiActive = ['wiki-system', 'wiki-article', 'wiki-article-list'].includes(firstSegment);
    }
    if (this.isWikiActive) {
      this.selectWikiCategory('all');
    }
  }


  ngOnInit() {
    this.testingHostStudents();
    this.studentRole = this.commonService.userRolesArr["Student"];
    this.hostRole = this.commonService.userRolesArr["Host"];
    this.userRole = this.commonService.getUserRole();
    this.getWikiData();
    this.getMediaData();
    this.getCourseSectionRoomNameData();
    this.getChatHistoryData();

    setTimeout(() => {
      this.setSidebarRoutes();
      this.getInitialAPIData();

      this.show_sidebar = false;
    }, 1000);
    this.getCourseListing(0, "", "", "")
    this.isData = false;
  }

  goToNotification(event) {
    console.log(event);
    event.preventDefault();
    if (this.router.url === '/notification-list') {
      // If already on notification-list route, reload the page
      const currentUrl = this.router.url;
      setTimeout(() => {
        this.router.navigateByUrl('/notification-detail', { skipLocationChange: true }).then(() => {
          this.router.navigate([currentUrl], { replaceUrl: true });
        });
      }, 800);
    }
    else {
      // If not on notification-list route, navigate to it
      setTimeout(() => {
        this.router.navigate(['/notification-list']);
      }, 800);
    }
  }

  isNotificationRouteActive(): boolean {
    // Check if the current route matches '/dashboard'
    return this.router.url === '/notification-list';
  }

  setSidebarRoutes() {
    this.dashboradRoute = this.commonService.getDashBoardRoutes(
      this.commonService.getUserRole()
    );
  }

  ngAfterViewInit() {
    var acc = document.getElementsByClassName("taggleClassEvent");
    var i;
    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.height) {
          panel.style.height = null;
        } else {
          panel.style.height = panel.scrollHeight + "px";
        }
      });
    }
  }

  navigateToDashboard() {
    setTimeout(() => {
      this.router.navigate(['/dashboard']);
    }, 800);
  }

  isDashboardRouteActive(): boolean {
    // Check if the current route matches '/dashboard'
    return this.router.url === '/dashboard';
  }

  collapseSideBarSection() {
    const pageelement = document.getElementById("pageContainer");
    if (pageelement.classList.contains("collapse-panel")) {
      this.renderer.removeClass(pageelement, "collapse-panel");
    } else {
      this.renderer.addClass(pageelement, "collapse-panel");
    }
  }

  testingHostStudents() {
    const loggedUserName = this.commonService.getUserName();
    const testHost =
      this.commonService.allowedLiveStreamTestingHost.includes(loggedUserName);
    const testStudent =
      this.commonService.allowedLiveStreamTestingUsers.includes(loggedUserName);
    this.hostRoomUrl = "/create-room-two";
    this.activeRoomUrl = "/user-room-section";
  }

  goToGeneralChat() {
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate(["/general-chat"]);
    });
  }

  ngOnDestroy() {
    this.generalChatContainer.unsubscribe();
    this.generalUserChatTermsContainer.unsubscribe()
  }

  getInitialAPIData() {
    this.getCourseSectionRoomName()
    this.getUserChatTermsAcceptStatus();
  }


  getCourseSectionRoomName() {
    const data = {
      method: "getCourseSectionRoomName",
      id: this.commonService.getUserId(),
      containerId: this.commonService.getDomainContainerId()
    }
    this.liveChatService.getCourseSectionRoomName(data).subscribe((res: any) => {
      this.dataService.changeGeneralChatStatus(res['data'])
    })
  }

  getChatRoomActiveStatus() {
    const data = {
      method: "getChatRoomActiveStatus",
      roomId: 1,
      containerId: this.commonService.getDomainContainerId(),
      id: this.commonService.getUserId()
    };
    console.log(data, 'ssss sdsds')
    this.liveChatService.getChatRoomActiveStatus(data).subscribe((res: any) => {
      console.log(res['data'], "response");
      this.dataService.changeGeneralChatStatus(res["data"]);
    });
  }

  openChatTermsDialogBox() {
    let message = 'This meeting has been expired.'
    this.dialogRef = this.dialog.open(UserChatTermsComponent, {
      data: {
        message: message,
      }
    })
    this.dialogRef.afterClosed().subscribe((res: any) => {
      if (res == 'true') {
      }
    })
  }

  getUserChatTermsAcceptStatus() {
    const data = {
      method: "getUserChatTermsAcceptStatus",
      roomId: 1,
      userId: this.commonService.getUserId(),
      containerId: this.commonService.getDomainContainerId(),
      id: this.commonService.getUserId()
    };
    this.liveChatService.getUserChatTermsAcceptStatus(data).subscribe((res: any) => {
      // console.log(res, 'tempo')
      if (res["data"] === undefined || res["data"] === "") {
        this.showChatMenu = false;
        this.dataService.changeUserGeneralChatTermsSource(false);
      } else {
        this.showChatMenu = res["data"];
        this.dataService.changeUserGeneralChatTermsSource(res["data"]);
      }
    });
  }

  getWikiData() {
    const data = {
      method: "getWikiCategory",
      id: this.commonService.getUserId(),
      containerId: this.commonService.getDomainContainerId(),
      searchCat: '',
      sort_by: ''
    }
    this.wikiService.getWikiCategory(data).subscribe((res) => {
      if (res['status']) {
        if (res['data'] != null || res['data'] != undefined) {
          this.wikiCategory = res['data'];
          this.wiki_notfound = false;
          this.isWikiData = true;
        } else {
          this.wiki_notfound = true;
          this.isWikiData = false;
        }
      } else {
        this.wiki_notfound = true;
        this.isWikiData = false;
      }
    });
  }

  getMediaData() {
    const paramCat = {
      method: "videoCategoryListDropDown",
      id: this.commonService.getUserId(),
      containerId: this.commonService.getDomainContainerId(),
      search_by_title: "",
      search_by_category: "",
      pageNumber: 0,
      sort_by: ""
    }
    this.videoService.getVideoListData(paramCat).subscribe((res) => {
      if (res['status']) {
        if (res['data'] != null || res['data'] != undefined) {
          this.media_notfound = false
          this.mediaCategory = res['data']
          this.isMediaData = true
        } else {
          this.media_notfound = true
          this.isMediaData = false
        }
      } else {
        this.media_notfound = true
        this.isMediaData = false
      }
    })
  }

  joinRoom() {
    // console.log(this.roomName , 'roomName in join ')
    this.chatService.joinRoom(this.roomName);
  }

  getCourseSectionRoomNameData() {
    const data = {
      method: "getCourseSectionRoomName",
      id: this.commonService.getUserId(),
      containerId: this.commonService.getDomainContainerId()
    }
    this.liveChatService.getCourseSectionRoomName(data).subscribe((res) => {
      if (res['data'] !== undefined) {
        this.dataService.changeGeneralChatStatus(res['data'])
        this.chatRoomId = res['data'][0].room_id
        this.roomName = res['data'][0].room_name
        this.joinRoom()
      } else {
        // this.router.navigate(['dashboard'])
      }
    })
  }

  getChatHistoryData() {
    let data = {
      method: 'getChatHistory',
      id: this.commonService.getUserId(),
      roomId: this.chatRoomId,
      startPoint: 0,
      containerId: this.commonService.getDomainContainerId()
    }
    this.chatService.getChatHistory(data).pipe(takeUntil(this.historyChatCompoent$)).subscribe((res) => {
      if (res['status']) {
        this.chat_notfound = false;
      } else {
        this.chat_notfound = true
      }
    });

  }


  getCourseListing(pageCounter, searchTitle, searchCat, sort) {
    this.isLoading = true;
    const videoData = {
      method: 'courseCategoryList',
      id: this.commonService.getUserId(),
      container_id: this.commonService.getDomainContainerId(),
      search_by_title: searchTitle,
      search_by_category: searchCat,
      pageNumber: pageCounter,
      sort_by: sort
    }
    this.dashboardService.getcourseListData(videoData).subscribe((res) => {
      this.isLoading = false;
      if (res['status']) {
        if (res['data'] != null || res['data'] != undefined) {
          this.notfound = false;
          this.courseDataContainer = res['data'].category_courses;
          this.uncategoryCourseDataContainer = res['data'].uncategory_courses;
          this.isData = true;
        } else {
          this.notfound = true;
          this.isData = false;
        }
      } else {
        this.notfound = true;
        this.isData = false;
      }

    });
  }



}

