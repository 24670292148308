import { Component, OnInit } from '@angular/core';
import { CommonService } from './../../service/common.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  siteDetail: any;
  address: any;
  email: any;
  phone: any;


  constructor(private commonService : CommonService) { }

  ngOnInit() {
  this.siteDetail =  this.commonService.getCurrentSiteDetail()
  this.address =  this.siteDetail.address;
  this.email =  this.siteDetail.email;
  this.phone =  this.siteDetail.pri_phone;
  }

}
