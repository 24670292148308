import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from './../../service/auth.service';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { CommonService } from './../../service/common.service';
import { UserService } from './../../service/user.service';
import { GeneralMessageComponent } from './../../dialogBox/general-message/general-message.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { DataService } from './../../service/data.service';

@Component({
  selector: 'app-change-user-password',
  templateUrl: './change-user-password.component.html',
  styleUrls: ['./change-user-password.component.scss']
})
export class ChangeUserPasswordComponent implements OnInit, OnDestroy {
  currentBtnThemeContainer: Subscription;
  buttonTheme: any;
  constructor(private router: Router, private route: ActivatedRoute, private authService: AuthService,
    private formBuilder: FormBuilder, private commonService: CommonService, private userService: UserService,
    private dialog: MatDialog , private dataService: DataService) {
      this.currentBtnThemeContainer = this.dataService.currentButtonTheme.subscribe(message => {
        this.buttonTheme = message;
      }, error => error);
     }
  newPasswordForm: FormGroup;
  currentPassword: FormControl;
  newPassword: FormControl;
  cnfPassword: FormControl;
  message: any = '';
  dialogRef: any;
  actStatus: boolean = false;
  messageCode:any;
  auth_token : string;
  language : string ; 
  msg : boolean = false ; 
  updatePswrd : boolean = false ; 

  ngOnInit() {
    this.auth_token = localStorage.getItem('rw_jwt');
    this.language = localStorage.getItem('locale')
    this.currentPassword = this.formBuilder.control('', [Validators.required])
    this.newPassword = this.formBuilder.control('', [Validators.required])
    this.cnfPassword = this.formBuilder.control('', Validators.required)
    this.newPasswordForm = this.formBuilder.group({
      currentPassword: this.currentPassword,
      newPassword: this.newPassword,
      cnfPassword: this.cnfPassword,
      method: 'changePasswordforStudent',
      id: this.commonService.getUserId(),
      username: this.commonService.getUserName(),
      auth_token : this.auth_token,
      language: this.language
    });
  }

  ngOnDestroy() {
    this.currentBtnThemeContainer.unsubscribe();
  }

  /**
   * @description update user password
   * @date 2019-09-16
   * @memberof ChangeUserPasswordComponent
   */
  newPasswordEvent() {
    this.messageCode = null;
    this.message = '';
    if (this.newPasswordForm.status == 'VALID') {
      this.userService.changeUserPassword(this.newPasswordForm.value).subscribe(res => {
       this.messageCode = res['messageCode']
        if (res['status']) {
          this.msg = true;
          this.updatePswrd = true
          this.message = res['message']
          this.newPasswordForm.reset()
          this.ngOnInit()
          //this.openGeneralDialogBox()
        } else {
          this.actStatus = res['status']
          this.msg = true;
          this.message = res['message']
          this.updatePswrd = false
        }
        setTimeout(() => {
          this.msg = false
        }, 4000);
      })
    } else {
      this.message = 'Please fill all fields';
    }
  }

  /**
   * @description open general messages dialog box
   * @date 2019-09-16
   * @memberof ChangeUserPasswordComponent
   */
  openGeneralDialogBox() {
    this.dialogRef = this.dialog.open(GeneralMessageComponent, {
      data: {
        message: this.message,
        status: this.actStatus
      }
    })
    this.message = '';
    this.dialogRef.afterClosed().subscribe(res => {
      if (res == 'true') {
        this.router.navigate(['edit-profile'])
      }
    })
  }

}
