
/********************************/
/*  For Prod Environment  */
/********************************/
export const environment = {
  production: true,
  application_version : '1.0.8',

  // ...............................LMS.......................................

  // BASE_API_URL: 'http://lms.devdemo.pro/api/',
 //  BASE_SITE_URL: 'http://lms.devdemo.pro/',
  // BASE_SITE_DOMAIN: window.location.host,

  // ...............................AZURE..........................................

  //  BASE_API_URL: "https://weber-lms.azurewebsites.net/backend/api/",
  // BASE_SITE_URL: "https://weber-lms.azurewebsites.net/backend/",
  // BASE_SITE_DOMAIN: 'weber-lms.azurewebsites.net/frontend/weber',
  // DASHBOARD_ROUTE: '/frontend/dashboard',
  // LOGIN_ROUTE: '/frontend/login',



  // ......................Retterlive...........................
 // BASE_API_URL: 'https://admin.retteracademy.com/api/',
//  BASE_SITE_URL: 'https://admin.retteracademy.com/',

// ........................weberrescueacademy......................
  BASE_API_URL: 'https://admin.retteracademy.com/api/',
  BASE_SITE_URL: 'https://admin.retteracademy.com/',
  SOCKET_URL: 'https://socket.retteracademy.com/',


 //  BASE_SITE_DOMAIN: 'weber.devdemo.pro',
  BASE_SITE_DOMAIN: window.location.host,
  DASHBOARD_ROUTE: 'dashboard',
  LOGIN_ROUTE: 'login',

  // ..............................................................................
  DEMO_SITE_DOMAIN: 'demo.lmss.org'
};

