import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from './../../../../service/common.service';
import { WikiService } from './../../service/wiki.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from './../../../../service/data.service';
import { ApiService } from './../../../../service/api.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-wiki-article-list',
  templateUrl: './wiki-article-list.component.html',
  styleUrls: ['./wiki-article-list.component.scss']
})
export class WikiArticleListComponent implements OnInit, OnDestroy {
  catId: any;
  articleList: any;
  currentLangContainer: Subscription;
  lang: number;
  isLoading: boolean = true;
  catgoryName: any;
  parentCat: any;
  articleDataContainer: any;
  searchArticleDataContainer: any;
  showNoDataErr: boolean;
  longDescription: any;
  articleForm: FormGroup;
  selectArticle: FormControl;
  selectArticleError: Boolean = false;
  notfound: Boolean = true;


  totalLengthOfPage: any[] = [];
  pagingLimit: number = 20;
  increasCount: number = 0;
  paginationClick: boolean = false;
  initialData: { method: string; id: any; containerId: any; catId: any; langId: number; };
  constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, private commonService: CommonService, private wikiService: WikiService, private router: Router, private dataService: DataService, private renderer: Renderer2, private apiService: ApiService) {
    this.catId = atob(this.route.snapshot.paramMap.get('catId'))

    this.currentLangContainer = this.dataService.currentLang.subscribe(message => {
      this.isLoading = true;
      this.getMainLangHeader();

      this.initialData = {
        method: "getArticlesByCategory",
        id: this.commonService.getUserId(),
        containerId: this.commonService.getDomainContainerId(),
        catId: this.catId,
        langId: this.lang
      }
      this.getArticlesByCategory(this.initialData, 0);

    }, error => error);

    this.getMainLangHeader();
  }

  getMainLangHeader() {
    if (localStorage.getItem('locale') == 'ger') {
      this.lang = 1
    } else if (localStorage.getItem('locale') == 'en') {
      this.lang = 2
    }
  }

  ngOnInit() {
    this.selectArticle = new FormControl(null, Validators.required);
    this.articleForm = this.formBuilder.group({
      selectArticle: this.selectArticle
    })
    this.wikiArticleByID()
  }

  onArticleChange() {
    this.checkValidate();
  }

  getArticlesByCategory(data, pageCount = 0) {
    console.log(data);

    this.totalLengthOfPage = [];
    this.increasCount = 0;
    if (pageCount > 0) {
      data.pageNumber = pageCount;
    }
    this.wikiService.getArticlesByCategory(data).subscribe((res) => {
      if (!res['jwt_status']) {
        this.commonService.openSessionExpireDialog();
        return;
      }
      this.catgoryName = res['category']
      this.parentCat = res['parentCat']
      this.longDescription = res['longDescription']
      this.searchArticleDataContainer = (res['data'] !== undefined) ? res['data'] : [];
      this.articleList = (res['data'] !== undefined) ? res['data'] : [];
      this.articleDataContainer = res['categoryData'];
      this.showNoDataErr = (this.articleList.length == 0) ? true : false;
      this.isLoading = false;

      const getTotalCount = parseInt(res['recordsCount']);
      const roundOffCount = Math.ceil(getTotalCount / this.pagingLimit);
      for (let i = 1; i <= roundOffCount; i++) {
        const pageItenData = {
          pageItem: this.increasCount
        }
        this.totalLengthOfPage.push(pageItenData);
        this.increasCount = this.increasCount + 20;
      }
      setTimeout(() => {
        const getPagingId = localStorage.getItem('studentFeedbackPaginationCounter');
        let pagingElement: any = '';
        pagingElement = document.getElementById('paging_link_' + getPagingId);
        if (pagingElement != null) {
          this.renderer.addClass(pagingElement, 'active');
        }
      }, 100);
      this.isLoading = false
      console.log("article category");

    })
    this.paginationClick = false;

  }

  getDataById(getCurrentPageCount: any) {
    this.isLoading = true
    this.paginationClick = true;
    const formPostData: any = {
      method: "getArticlesByCategory",
      id: this.commonService.getUserId(),
      containerId: this.commonService.getDomainContainerId(),
      catId: this.catId,
      langId: this.lang,
      pageNumber: 0,
    }
    this.getArticlesByCategory(formPostData, getCurrentPageCount);
    this.setCounterLink(getCurrentPageCount)
  }

  setCounterLink(counterId: any) {
    localStorage.setItem('articleLstPaginationCounter', counterId);
  }

  goToCategory() {
    if (this.parentCat == 0) {
      this.router.navigate(['wiki-system']);
    } else {
      this.router.navigate(['/wiki-article-list/' + btoa(this.parentCat)])
    }
  }

  goToArticleDetail(articleId) {
    this.router.navigate(['wiki-article-detail/' + btoa(articleId)])
  }

  ngOnDestroy() {
    localStorage.removeItem('articleLstPaginationCounter');
    this.currentLangContainer.unsubscribe();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  goToArticleDetailList(catId) {
    this.router.navigate(['/wiki-article-list/detail/' + btoa(catId)], {
      skipLocationChange: true,
    })
  }

  checkValidate() {
    if (this.articleForm.status == 'VALID' && this.selectArticle.valid) {
      this.selectArticleError = false;
    } else {
      this.selectArticleError = true;
    }
  }


  getArticleListing(pageCounter, searchTitle, searchArticle) {
    this.isLoading = true;
    const data = {
      method: "getSearchWikiArticle",
      id: this.commonService.getUserId(),
      containerId: this.commonService.getDomainContainerId(),
      searchArticle
    }
    this.wikiService.getSearchWikiArticle(data).subscribe((res) => {
      this.isLoading = false;
      if (!res['jwt_status']) {
        this.commonService.openSessionExpireDialog();
        return;
      }
      if (res['status']) {
        if (res['data'] != null || res['data'] != undefined) {
          this.notfound = false;
          this.searchArticleDataContainer = res['data'];
        } else {
          this.notfound = true;
        }
      } else {
        this.notfound = true;
      }

    });
  }

  resetForm() {
    this.selectArticleError = false;
    if (this.articleForm.value.selectArticle != null) {
      this.articleForm.value.selectArticle == ''
      // this.getArticleListing(0, '', '');
    }
    this.getArticlesByCategory(this.initialData, 0);
  }

  searchByArticle() {
    this.checkValidate();
    console.log(this.selectArticle.value)
    if (this.articleForm.status == 'VALID' && this.selectArticle.valid) {
      this.getArticleListing(0, '', this.articleForm.value.selectArticle == 'all' ? '' : this.articleForm.value.selectArticle);
    } else {

    }
  }

  wikiArticleByID() {
    this.dataService.wikiCategoryId.subscribe(res => {
      let catid = res.id;

      let data = {
        method: "getArticlesByCategory",
        id: this.commonService.getUserId(),
        containerId: this.commonService.getDomainContainerId(),
        catId: catid,
        langId: this.lang
      }

      console.log(this.router.url);
      


      if (res == 'gotoback') {
        this.goToCategory();
        return

      }

      if (catid != undefined) {
        this.getArticlesByCategory(data, 0);
      }
    })
  }
}
