import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HeaderService } from './../../service/header.service';
import { Subscription, from } from 'rxjs';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { CourseService } from './../../service/course.service';
import { ModuleService } from './../../service/module.service';
import { LessonService } from './../../service/lesson.service';
import { DataService } from './../../service/data.service';
import { CommonService } from './../../service/common.service';
import { SiteDomainService } from './../../service/sitedomain.service';
import { ApiService } from './../../service/api.service';
import { AppService } from './../../service/app.service';
import { Title } from '@angular/platform-browser';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/service/user.service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { environment } from 'src/environments/environment';




@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss']
})
export class SiteHeaderComponent implements OnInit, OnDestroy {

  breadCrumbCourseName: any;
  private childParamSubscription: Subscription;
  private paramSubscription: Subscription;
  @ViewChild('imageFileInput') imageFileInput: ElementRef;

  isMenuToggle;
  domain: any;
  themestyle: any;

  loggedInStatus: boolean = false;
  activeComponent;
  courseId;
  active: boolean;

  // breadCrumbId's
  sectionSlug: any; dashboradRoute: any;
  loggedUserDetail: any;
  userRole: any;
  showParentSection: boolean;
  userFullName: any;


  breadCourseId;

  breadLessonId;
  breadLessonId2;

  breadModuleId;
  breadModuleId2;

  coursePostData;
  moduleListPost;
  lessonListPost;

  courseDetail;
  moduleDetail;
  lessonDetail;

  breadcrumbCourseName;
  breadcrumbModuleName;
  breadcrumbLessonName;

  lessonSlugStatus: boolean;
  moduleSlugStatus: boolean;
  courseSlugStatus: boolean;

  headerBgColor: any;
  headerTxtColor: any;
  isSmallScreen: any;
  currentMessageContainer: Subscription;
  currentLessonSlugContainer: Subscription;
  currentUserProfileImgContainer: Subscription;
  currentHeaderUserNameComtainer: Subscription;

  notificationCount: any;
  notificationIds: any;
  institutLogo: any;
  userThumb: any;
  sitePath: any;
  instituteDetail: any[] = [];
  userImgName: any;
  loggedHeaderUser: any;
  langArr: { id: number; name: string; img: string; }[];
  mainLangName: string;
  mainLangImg: string;

  currentLangContainer: Subscription;
  showinstName: any;
  notificationInterval: any;

  hostRole: Boolean = false

  uId: any
  cId: any
  sk: any

  selectedDomain: string = '';
  previousValue: any;
  currentPage: string = '';
  selectedLang: number;
  isSidebarOpen: boolean = false;
  isAppMenuSidebarOpen: boolean = false;
  lang_id: any;


  showErrorMsg: boolean = false;
  isProfileImgLoading = false;
  uploadImg: File;
  userProfileimg: any;
  errorMsg: any;
  leftbar: boolean = false;
  rightbar: boolean = false;
  userImage: string;
  version: string;
  isTokenCookiePresent: boolean = false;

  constructor(private router: Router, private headerService: HeaderService, private route: ActivatedRoute,
    private courseService: CourseService, private moduleService: ModuleService, private lessonService: LessonService,
    private dataService: DataService, private commonService: CommonService, private breakpointObserver: BreakpointObserver, private userService: UserService,
    // tslint:disable-next-line: max-line-length
    private domainName: SiteDomainService, private apiService: ApiService, private appService: AppService, private titleService: Title, private translateService: TranslateService
  ) {
    this.domain = this.domainName.getDomainName();
    this.getSiteDetail(this.domain);
    this.getUnReadNotificationCount();
    // this.domain = window.location.host;
    // this.route.snapshot.paramMap.get('domain');


    this.currentLangContainer = this.dataService.currentLang.subscribe(message => {
      this.getMainLangHeader();
    }, error => error);

    this.getMainLangHeader();

    if (localStorage.getItem('locale') === 'en') {
      this.langArr = [
        {
          id: 1,
          name: 'English',
          img: 'english.png'
        },
        {
          id: 2,
          name: 'German',
          img: 'german.png'
        }
      ];
    } else {
      this.langArr = [
        {
          id: 1,
          name: 'Englisch',
          img: 'english.png'
        },
        {
          id: 2,
          name: 'Deutsch',
          img: 'german.png'
        }
      ];
    }


    this.currentUserProfileImgContainer = this.dataService.currentProfileImg.subscribe(message => {
      if (message != null) {
        this.getUserProfileThumb();
      }
    }, error => error);

    this.currentHeaderUserNameComtainer = this.dataService.currentHeaderUserName.subscribe(message => {
      this.loggedHeaderUser = localStorage.getItem('headerFullName');
      if ((this.loggedHeaderUser) && (message != null) && (message.length > 0)) {
        this.userFullName = message;
      } else {
        this.userFullName = this.loggedHeaderUser;
      }
    }, error => error);

    this.courseId = this.route.snapshot.paramMap;
    this.activeComponent = route.snapshot.routeConfig.component.name;
    if (this.activeComponent == 'FrontComponent') {
      this.active = true;
    } else {
      this.active = false;
    }

    this.childParamSubscription = this.route.firstChild.paramMap.subscribe(
      (params: ParamMap): void => {
        this.breadCourseId = params.get('courseId')
        this.breadModuleId = params.get('moduleId')
        this.breadModuleId2 = params.get('moduleId')
        this.breadLessonId = params.get('lessonId')
        this.breadLessonId2 = params.get('lessonId')
        this.sectionSlug = params.get('sectionId')
      },
      error => error
    )

    // module details
    this.dataService.changeMessage(this.breadModuleId2);
    this.currentMessageContainer = this.dataService.currentMessage.subscribe(message => {
      if (message != null) {
        this.breadModuleId2 = message;
        this.breadCrumModuleManager(message, 'getModuleBySlug')
      } else {
        // this.moduleSlugStatus = false
        // this.breadCrumModuleManager( null ,"getModuleBySlug")
      }
    }, error => error);

    this.dataService.changeLessonSlug(this.breadLessonId2);
    this.currentLessonSlugContainer = this.dataService.currentLessonSlug.subscribe(lessonSlug => {

      if (lessonSlug != null) {
        this.breadCrumLessonManager(lessonSlug, 'getLessonByLessonSlug')
      } else {
        // this.lessonSlugStatus = false
        // this.breadCrumLessonManager( null ,"getLessonByLessonSlug")
      }

    }, error => error);

    this.isSmallScreen = breakpointObserver.isMatched('(max-width: 767px)');
    if (this.isSmallScreen) {
      this.isMenuToggle = true;
    }

    const getlocalStrg = JSON.parse(localStorage.getItem('theme_style'));
    if (getlocalStrg) {
      this.themestyle = getlocalStrg;
      this.siteLogo(this.themestyle.theme_style.logo);
      localStorage.setItem('domain',this.themestyle.domain)
  
      this.styleThemeData(this.themestyle);
    }


    this.getUserProfileThumb();


    // let currentURL = window.location.href;
    // var newURL = currentURL.split('?')[0];
    // history.replaceState(null, null, newURL);

  }

  getMainLangHeader() {
    if (localStorage.getItem('locale') == 'ger') {
      this.mainLangName = 'German';
      this.mainLangImg = 'german.png';
    } else if (localStorage.getItem('locale') == 'en') {
      this.mainLangName = 'English';
      this.mainLangImg = 'english.png';
    }
  }

  changeLang(event) {
    if (event.target.value == 1) {
      localStorage.setItem('locale', 'en');
      this.dataService.changeAppLag('en');
      window.location.reload();
    } else {
      localStorage.setItem('locale', 'ger');
      this.dataService.changeAppLag('ger');
      window.location.reload();
    }

  }


  changeLangByClick(id: number) {
    if (id === 1) {
      localStorage.setItem('locale', 'en');
      this.dataService.changeAppLag('en');
      window.location.reload();
    } else {
      localStorage.setItem('locale', 'ger');
      this.dataService.changeAppLag('ger');
      window.location.reload();
    }
  }

  getUserProfileThumb() {
    // tslint:disable-next-line: variable-name
    const user_img = localStorage.getItem('user_img');
    if (user_img) {
      this.userThumb = this.apiService.getUserProfilePath() + user_img;
    } else {
      this.userThumb = false;
    }
  }

  setFavicon(logo: any, name: any) {

    //  const basepath: any = this.siteLogo(logo);
    //  this.appService.setAppFavicon(basepath);
    this.titleService.setTitle(name);
  }

  siteLogo(logoname: any) {
    if (logoname) {
      if (this.commonService.getInstituteLogo(logoname)) {
        this.institutLogo = this.commonService.getInstituteLogo(logoname);
      } else {
        this.institutLogo = null;
      }
    } else {
      this.institutLogo = null;
    }
    return this.institutLogo;
  }

  breadCrumLessonManager(message, methodtype) {
    this.breadLessonId = message;
    this.lessonListPost = {
      id: this.commonService.getUserId(),
      lessonSlug: this.breadLessonId,
      courseSlug: this.breadCourseId,
      moduleSlug: this.breadModuleId2,
      sectionSlug: this.sectionSlug,
      method: methodtype
    };
    //  this.getLessonDetail(this.lessonListPost)
  }

  breadCrumModuleManager(message, methodtype) {
    this.breadModuleId = message;
    this.moduleListPost = {
      slug: this.breadModuleId,
      method: methodtype
    };
    // this.getModuleList(this.moduleListPost)
  }


  containerId: any
  ngOnInit() {
    this.containerId = localStorage.getItem('containerId');
    this.loggedUserDetail = JSON.parse(localStorage.getItem('loggedUser'));
    // this.userImage = localStorage.getItem('user_img')
    if (this.loggedUserDetail != null) {
      this.loggedUserDetail.first_name = (this.loggedUserDetail.first_name != null) ? this.loggedUserDetail.first_name : '';
      this.loggedUserDetail.middle_name = (this.loggedUserDetail.middle_name != null) ? this.loggedUserDetail.middle_name : '';
      this.loggedUserDetail.last_name = (this.loggedUserDetail.last_name != null) ? this.loggedUserDetail.last_name : '';


      // tslint:disable-next-line: max-line-length
      this.userFullName = this.loggedUserDetail.first_name + ' ' + this.loggedUserDetail.middle_name + ' ' + this.loggedUserDetail.last_name;
    }


    if (this.loggedUserDetail != null) {
      this.userRole = this.loggedUserDetail.role_id;
      if (this.userRole == this.commonService.userRolesArr['Student']) {
        this.showParentSection = true;
      } else {
        this.hostRole = true
        this.showParentSection = false;
      }
    }

    this.sitePath = this.apiService.getSiteFolderPath();
    // this.getSiteDetail(this.domain)
    const loggedInStatusStr = localStorage.getItem('loggedIn');
    this.loggedInStatus = loggedInStatusStr === 'true' 
    
    if (this.loggedInStatus) {
      this.notificationInterval = setInterval(() => {
        this.getUnReadNotificationCount();
      }, 20000);
    }

    if (this.breadCourseId) {
      this.coursePostData = {
        id: this.commonService.getUserId(),
        courseSlug: this.breadCourseId,
        sectionSlug: this.sectionSlug,
        method: 'courseDetailsBySlugs'
      };
      // this.getCourseDetail(this.coursePostData)
    }

    this.getInstituteDetail();

    if (localStorage.getItem('locale') === 'ger') {
      this.selectedLang = 2
    }
    else {
      this.selectedLang = 1
    }

    this.getUserData()

    if (localStorage.getItem('user_img') != undefined) {
      this.userImage = localStorage.getItem('user_img')
    } else {
      this.userImage = localStorage.getItem('user_img')
    }

    // setTimeout(() => {
    //   this.userImage = localStorage.getItem('user_img')
    // }, 2000);
    this.version = environment.application_version;

    const url = this.router.url;
    this.updateLoggedInStatus(url);

    // Subscribe to NavigationEnd event to update loggedInStatus on route changes
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentUrl = this.router.url;
        this.updateLoggedInStatus(currentUrl);
      }
    });
    
  }

  updateLoggedInStatus(url: string): void {
    // Check if the current route is '/login' or '/user-registration'
    if (url === '/login' || url === '/user-registration' || url === '/forgot-password' || url.startsWith('/newpassword/')) {
      this.loggedInStatus = false;
    } else {
      this.loggedInStatus = true;
    }

  }

  ngOnDestroy() {
    this.currentMessageContainer.unsubscribe();
    this.currentLessonSlugContainer.unsubscribe();
    this.currentUserProfileImgContainer.unsubscribe();
    if (this.notificationInterval) {
      clearInterval(this.notificationInterval);
    }
    // this.currentLangContainer.unsubscribe();
  }

  toggleHeaderMenu() {
    this.isMenuToggle = !this.isMenuToggle;
  }

  getSiteDetail(segmentName) {

    this.headerService.getSiteDetail(segmentName).subscribe(res => {

      this.dataService.changeShowRegisterLink(res['data'].is_registration);
      // tslint:disable-next-line: no-string-literal
      this.themestyle = res['data'];
      this.showinstName = (this.themestyle.hide_inst_name == 0) ? true : false;
      this.styleThemeData(this.themestyle);
      this.siteLogo(this.themestyle.theme_style.logo);
      const buttonTheme = {
        button_back_color: this.themestyle.theme_style.button_back_color,
        button_text_color: this.themestyle.theme_style.button_text_color
      };
      this.dataService.changeButtonThemeSource(buttonTheme);
      localStorage.setItem('theme_style', JSON.stringify(this.themestyle));


      // please change here for fav
      this.setFavicon(this.themestyle.theme_style.logo, this.themestyle.name);
    }, error => error);
  }

  styleThemeData(theme) {
    this.headerBgColor = { background: theme.theme_style.header_back_color };
    this.headerTxtColor = { color: theme.theme_style.header_text_color };
  }

  logout() {
    this.commonService.openLogoutConfirmationDialog();
    this.closeSidebar();
  }

  myCourses(event) {
    event.preventDefault();
    this.router.navigate(['/user-course-list']);
  }

  myProfile() {
    this.router.navigate(['/profile']);
    this.currentPage = 'profile';
  }

  getCourseDetail(courseData) {
    this.courseService.getCourseDetail(courseData).subscribe(res => {
      this.courseDetail = res['data'];
      if (this.courseDetail != undefined) {
        this.courseSlugStatus = true;
        this.breadcrumbCourseName = this.courseDetail.name;
      } else {
        this.courseSlugStatus = false;
      }
    }, error => error);
  }

  getUnReadNotificationCount() {
    const postNotificationCountData = {
      method: 'notificationsCount',
      id: this.commonService.getUserId()
    };

    this.domainName.getUnreadNotificationCount(postNotificationCountData).subscribe(res => {
     if(res['status']){
      if (res['data']) {
        this.dataService.changeNotificationId(res['data']);
        this.domainName.notificationCount = res['count'];
        this.userFullName = res['userFullName'];
        // localStorage.setItem('headerFullName', this.userFullName);
        this.dataService.changeHeaderUserName(this.userFullName);
      }
     }else{
      this.commonService.openSessionExpireDialog()
     }
    });

  }


  getModuleList(moduleData) {
    this.moduleService.getModuleList(moduleData).subscribe(res => {
      this.moduleDetail = res['data']
      if (this.moduleDetail != undefined) {
        this.moduleSlugStatus = true;
        this.breadcrumbModuleName = this.moduleDetail.name;
      } else {
        this.moduleSlugStatus = false;
      }
    }, error => error);
  }

  getLessonDetail(lessonData) {
    this.lessonService.getLessonList(lessonData).subscribe(res => {
      this.lessonDetail = res['data']
      if (this.lessonDetail != undefined) {
        this.lessonSlugStatus = true;
        this.breadcrumbLessonName = this.lessonDetail.name;
      } else {
        this.lessonSlugStatus = false;
      }
    },
      error => error
    );
  }

  goToDashboard(event) {
    event.preventDefault();
    this.router.navigate(['/dashboard']);
  }

  // goToHomePage(event) {
  //   event.preventDefault()
  //   const localVar = localStorage.getItem('loggedUser');
  //   if (localVar) {
  //     this.router.navigate(['/dashboard'])
  //   } else {
  //     this.router.navigate(['/login'])
  //   }

  // }

  goToHomePage(event) {
    event.preventDefault();
    const userData = JSON.parse(localStorage.getItem('loggedUser'));
    this.dashboradRoute = this.commonService.getDashBoardRoutes(userData.role_id);
    if (userData) {
      this.router.navigate(['/' + this.dashboradRoute]);
    } else {
      this.router.navigate(['/login']);
    }

  }

  goToNotificationList(event) {
    console.log(event); 
    event.preventDefault();
    if (this.router.url === '/notification-list') {
      // If already on notification-list route, reload the page
      const currentUrl = this.router.url;
      this.router.navigateByUrl('/notification-detail', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl], { replaceUrl: true });
      });
    } 
    else {
      // If not on notification-list route, navigate to it
      this.router.navigate(['/notification-list']);
    }
  }

  

  goToProfile(event) {
    event.preventDefault();
    this.router.navigate(['/profile']);
  }

  goToNotification() {
    this.router.navigate(['/notification-list']);
  }

  goToMessages() {
    this.router.navigate(['/message-list']);
  }

  routeToViewParents() {
    this.router.navigate(['view-parents']);
  }

  purchaseHistory() {
    this.router.navigate(['purchase-history']);
    this.currentPage = 'purchaseHistory';
  }

  getInstituteDetail() {
    const detail = {
      method: 'getUserContainerByUserId',
      id: this.commonService.getUserId()
    }
    this.domainName.getInstituteDomain(detail).subscribe(res => {
      console.log(res);
      if (res['status']) {
        this.instituteDetail = res.data;
        let domainFound = this.instituteDetail.some(item => item.domain === this.domain);
        console.log(domainFound);
        
        if (!domainFound) {
          this.commonService.openNotPermissionDialog();
        }


      } else {
        this.commonService.openNotPermissionDialog()
      }
      
    })
  }

  isCookiePresent(cookieName) {
    let cookies = document.cookie.split(';');
    for (const element of cookies) {
      let cookie = element.trim();
      if (cookie.indexOf(cookieName + '=') === 0) {
        this.isTokenCookiePresent = true;
        return true;
      }
    }
    this.isTokenCookiePresent = false;
    return false;
  }

  navigateToSelectedDomain(event) {
    event.preventDefault();
    let user_id = this.instituteDetail[event.target.selectedIndex].user_id.replace(/\s/g, '');
    let cont_id = this.instituteDetail[event.target.selectedIndex].container_id.replace(/\s/g, '');
    let scrt_key = this.instituteDetail[event.target.selectedIndex].secret_key.replace(/\s/g, '');

    let uid = encodeURIComponent(user_id);
    let cid = encodeURIComponent(cont_id);
    let sk = encodeURIComponent(scrt_key);
    // if (this.isCookiePresent('rw_jwt')) {
      let newUrl = "https://" + event.target.value + `/switch-institute?uid=${uid}&cid=${cid}&sk=${sk}`
      window.location.href = newUrl;
    // } else {
    //   this.commonService.openSessionExpireDialog()
    // }
  }

  routeToChangePassword() {
    this.router.navigate(['change-user-password'])
  }

  openSidebar() {
    this.isSidebarOpen = true;
    this.rightbar = true;
    this.closeAppMenuSidebar()
  }

  closeSidebar() {
    this.isSidebarOpen = false;
  }


  openAppMenuSidebar() {
    this.isAppMenuSidebarOpen = !this.isAppMenuSidebarOpen;
    this.leftbar = true;
    this.closeSidebar()
  }

  closeAppMenuSidebar() {
    this.isAppMenuSidebarOpen = false;
  }

  chooseFile(): void {
    this.imageFileInput.nativeElement.click();
  }

  getUserProfileImg(userImg) {
    if (userImg) {
      this.userProfileimg = this.apiService.getUserProfilePath() + userImg;
    } else {
      this.userProfileimg = null;
    }
    localStorage.setItem('user_img', userImg);
    this.dataService.changeProfileImgName(userImg);
  }

  userProfileImg(event) {
    this.showErrorMsg = false;
    this.isProfileImgLoading = true;

    this.lang_id = localStorage.getItem('locale') === 'ger' ? 1 : 2;

    this.uploadImg = event.target.files[0];

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const base64 = e.target.result;

      const imgData = {
        'id': this.commonService.getUserId(),
        'method': 'UserProfilePictureUpdate',
        'user_img_file': base64,
        'lang_id': this.lang_id,
        'authtoken': localStorage.getItem('rw_jwt')
      }

      this.userService.profileImage(imgData).subscribe((res) => {
        console.log(res);

        if (res['status']) {
          this.getUserProfileImg(res['data']['profile_image_url']);
          this.userImage = res['data']['profile_image_url'];
          console.log(this.userImage);
          localStorage.setItem('user_img', this.userImage);
        } else {
          this.errorMsg = res['message'];
          this.showErrorMsg = true;
          setTimeout(() => {
            this.showErrorMsg = false;
          }, 3000);
        }
        this.isProfileImgLoading = false;
      }, error => {
        console.error("Error: ", error);
        this.isProfileImgLoading = false;
      });
    };
    reader.readAsDataURL(this.uploadImg);
  }


  getUserData() {
    const geTUserpost = {
      method: 'getUserData',
      id: this.commonService.getUserId()
    }
    this.userService.getUserData(geTUserpost).subscribe(res => {
      if (res['status']) {
        // setTimeout(() => {
        localStorage.setItem('user_img', res['data'].profile_image_url)
        this.userImage = res['data'].profile_image_url
        // }, 1000);
      }
    })
  }

}


