import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public API_URL: string;
  public IMG_SITE_PATH: string;
  public SITE_URL: string;
  public SITE_FOLLDER_PATH: string;
  public CENTRAL_API_URL: string;
 public nodeServerUrl:String;
  constructor() { }

  getApiURL() {
    this.API_URL = environment.BASE_API_URL;
    return this.API_URL;
  }

  getCentralAPIURL(){
    this.CENTRAL_API_URL = 'http://devdemo.pro:56/rescue-world/UserApi/';
    return this.CENTRAL_API_URL;
  }

 getNodeServerUrl(){
  this.nodeServerUrl = 'https://video-lms.azurewebsites.net/'
  return this.nodeServerUrl
  }

  getCourseImgPath() {
    this.IMG_SITE_PATH = 'https://static.weberrescueworld.com/academy-uploads/';
    return this.IMG_SITE_PATH;
  }

  getApiSitePath() {
    this.SITE_URL = environment.BASE_SITE_URL;
    return this.SITE_URL;
  }

  getSiteLogoPath() {
    this.SITE_URL = 'https://static.weberrescueworld.com/academy-uploads/containers/logo/'
    return this.SITE_URL;
  }


  getSiteFolderPath() {
  if (environment.production === true) {
      this.SITE_FOLLDER_PATH = window.location.origin + '/';
    } else {
      this.SITE_FOLLDER_PATH = window.location.origin + "/";
    }
    return this.SITE_FOLLDER_PATH;
  }

  getUserProfilePath() {
    const userProfilePath = 'https://static.weberrescueworld.com/academy-' + 'uploads/user_profile/';
    return userProfilePath;
  }

  getVideoUploadPath() {
    const videoUploadPath = 'https://static.weberrescueworld.com/academy-uploads/';
    return videoUploadPath;
  }

  setHeaderValJWT(jwtParam: any) {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-KEY': 'LMSAPI@123#',
      'Token': jwtParam,
    });
    return httpHeaders
  }
  setHeaderValJWTForImage(jwtParam: any) {
    const httpHeaders = new HttpHeaders({
      'Accept': 'application/json;q=0.9,*/*;q=0.8',
      'X-API-KEY': 'LMSAPI@123#',
      'Token': jwtParam,
    });
    return httpHeaders;
  }
  setHeaderVal() {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-KEY': 'LMSAPI@123#'
    });
    return httpHeaders;
  }

  setCentralisedLoginHeaderVal() {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Apitoken':'yZpZSilzTnSePxBAy3JpLzfRFxEPx0kym7IwBlIX'
    });
    return httpHeaders;
  }


  getJWT() {
    return localStorage.getItem('jwt');
  }
  getUser() {return 'Users'}
  getCourse() { return 'course'; }
  getContainer() { return 'container'; }
  getLoginRegister() { return 'Login_Register'; }
  getQuiz() { return 'quiz'; }
  getPoll() { return 'Poll'; }
  getNotification() { return 'Notifications'; }
  getAssesment() { return 'Assessments'; }
  getGradeBook() { return 'Gradebook'; }
  getProfile() { return 'Profile'; }
  getStudent() { return 'Student'; }
  getGraderDashboard() { return 'GraderDashboard'; }
  getGrader() { return 'Grader'; }
  getUsers() { return 'Users'; }
  getInstructor() { return 'Instructor'; }
  getCourseGroup() { return 'Course_Group'; }
  getGroupLeader() { return 'GroupLeader'; }
  getDemo() { return 'Demo'; }
  getMarketPlace(){ return 'Marketplace'; }
  getCentralPostLogin() { return 'postLogin'; }
  getAddCenterUserAgentDetails() { return 'addCenterUserAgentDetails';}
  getVideo() { return 'Video';}
  getStudentTime() { return 'StudentTime'; }
  getDashboard(){ return 'Dashboard'; }
  getCoursePreview(){ return 'Course_Preview'; }
  getUserRegistration() { return 'UserRegistration'; }
  getLiveStream(){return 'LiveStream'}
 getFeedBack(){return 'Feedback';}
  getChatSytem(){ return 'ChatSystem' }
  getSwitchDomain(){return 'SwitchDomain'}
}
