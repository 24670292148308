// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-description-container {
  max-height: auto;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  display: block;
  width: 100%;
}
.course-description-container.open {
  max-height: none;
}

.cwp-img {
  /*min-height: 350px;max-height:450px;*/
  position: relative;
  overflow: hidden;
  height: 450px;
  /* padding-bottom: 60px;*/
  background: #333;
}
.cwp-img img {
  /*width:100%; height:100%; object-fit: contain; border-radius:4px;*/
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  position: relative;
  top: calc(50% - 30px);
  left: 50%;
  transform: translate(-50%, -50%);
}

.cwp-setion-date {
  background: #000;
  padding: 10px 15px;
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  color: #fff;
}

.readMoreBtn {
  text-align: right;
}
.readMoreBtn .btn {
  text-align: right;
  font-size: 13px;
  padding-bottom: 0;
}

.module-document-item {
  display: inline-block;
  min-width: 120px;
  padding-right: 25px;
  padding-bottom: 20px;
}
.module-document-item a {
  position: relative;
  font-size: 17px;
  font-weight: 500;
  padding-left: 26px;
}
.module-document-item a:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #007bff;
  border-radius: 50%;
  border: 3px solid #fff;
  box-shadow: 0 0 0 1px #007bff;
  top: 4px;
  left: 5px;
}

.quiz-list-container button {
  position: relative;
  padding: 5px 5px 5px 30px;
  margin: 0;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  font-family: var(--poppins);
  color: var(--primarycolor);
  width: 100%;
  border: 0;
  text-align: left;
  background: transparent;
}
.quiz-list-container button .circle-item {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  margin: 0;
  left: 0;
  top: 7px;
  bottom: 0;
  background: #fff;
  border: 3px solid #3180dc;
}
.quiz-list-container button.checked {
  pointer-events: auto;
  color: #000;
}
.quiz-list-container button.checked .circle-item {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  margin: 0;
  left: 0;
  top: 7px;
  bottom: 0;
  background: #fff;
  border: 3px solid #000;
}
.quiz-list-container button.is-complete .circle-item {
  border: 3px solid #fff;
}
.quiz-list-container button.is-complete .circle-item:before {
  position: absolute;
  top: -2px;
  content: "\\f121";
  font-size: 7px;
  color: #fff;
  font-family: var(--flaticon);
  background: #1f9c3a;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  left: -3px;
  padding: 3px 5px;
}
.quiz-list-container a {
  position: relative;
  padding: 5px 5px 5px 30px;
  margin: 0;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  font-family: var(--poppins);
  color: var(--primarycolor);
}
.quiz-list-container a .circle-item {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  margin: 0;
  left: 0;
  top: 7px;
  bottom: 0;
  background: #fff;
  border: 3px solid #3180dc;
}
.quiz-list-container a.checked {
  pointer-events: auto;
  color: #000;
}
.quiz-list-container a.checked .circle-item {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  margin: 0;
  left: 0;
  top: 7px;
  bottom: 0;
  background: #fff;
  border: 3px solid #000;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
