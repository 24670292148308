import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from './../../../../service/common.service';
import { WikiService } from './../../service/wiki.service';
import { DataService } from './../../../../service/data.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from './../../../../service/api.service';

@Component({
  selector: 'app-wiki-system',
  templateUrl: './wiki-system.component.html',
  styleUrls: ['./wiki-system.component.scss']
})
export class WikiSystemComponent implements OnInit, OnDestroy {
  categoryList: any;
  selectCategoryError: Boolean = false
  currentLangContainer: Subscription;
  isLoading = false;
  lang: number;
  notfound: Boolean = true;
  articleDataContainer: any;
  categoryForm: FormGroup;
  selectCategory: FormControl;
  sortCategory: FormControl;
  buttonTheme: { button_back_color: any; button_text_color: any; };
  currentBtnThemeContainer: Subscription;
  selectedOption: string;
  showPlaceholder: boolean = true;
  wikiCatId: number;

  azurePath: any;

  arrayOfSortObject = [
    {
      value: "alphabetically",
      name: "Alphabetically"
    },
    {
      value: "reversed_alphabetically",
      name: "Reversed alphabetically"
    },
    {
      value: "by_latest",
      name: "By latest"
    },
    {
      value: "by_oldest",
      name: "By oldest"
    },
  ]

  constructor(private commonService: CommonService, private wikiService: WikiService, private dataService: DataService, private formBuilder: FormBuilder, private router: Router, private apiService: ApiService) {
    this.currentLangContainer = this.dataService.currentLang.subscribe(message => {
      this.isLoading = true;
      this.getMainLangHeader();
      // this.getCategoryListing(0, '', '','');
    }, error => error);

    this.currentBtnThemeContainer = this.dataService.currentButtonTheme.subscribe(message => {
      this.buttonTheme = message;

    }, error => error);

  }

  getMainLangHeader() {
    if (localStorage.getItem('locale') == 'ger') {
      this.lang = 1
    } else if (localStorage.getItem('locale') == 'en') {
      this.lang = 2
    }
  }

  ngOnInit() {
    this.azurePath = this.apiService.getCourseImgPath()
    this.wikiCategoryId()
    // this.getArticleCategories()
    this.selectCategory = new FormControl(null, Validators.required);
    this.categoryForm = this.formBuilder.group({
      selectCategory: this.selectCategory,
      sortCategory: this.sortCategory
    })
    this.selectedOption = null; // Initialize selectedOption
  }

  ngOnDestroy() {
    this.currentLangContainer.unsubscribe()
  }

  getCategoryListing(pageCounter, searchTitle, searchCat, sort) {
    this.isLoading = true;
    const data = {
      method: "getWikiCategory",
      id: this.commonService.getUserId(),
      containerId: this.commonService.getDomainContainerId(),
      searchCat:searchCat,
      sort_by: sort
    }
    this.wikiService.getWikiCategory(data).subscribe((res) => {
      this.isLoading = false;
      if (!res['jwt_status']) {
        this.commonService.openSessionExpireDialog();
        return;
      }
      if (res['status']) {
        if (res['data'] != null || res['data'] != undefined) {
          this.notfound = false;
          this.articleDataContainer = res['data'];
        } else {
          this.notfound = true;
        }
      } else {
        this.notfound = true;
      }

    });
  }

  getArticleCategories() {
    const data = {
      method: "articleCategoryListDropdown",
      id: this.commonService.getUserId(),
      containerId: this.commonService.getDomainContainerId()
    }
    this.wikiService.getArticleCategories(data).subscribe((res) => {
      this.categoryList = res['data']
    })
  }

  resetForm() {
    this.selectCategoryError = false;
    this.selectedOption = null;
    this.showPlaceholder = true;
    if (this.categoryForm.value.selectCategory != null || this.categoryForm.value.sortCategory != null) {
      this.getCategoryListing(0, '', '', '');
    }
  }

  onCategoryChange(option) {
    console.log('Searching for:', option);
    if (option !== null) {
      this.showPlaceholder = false;
    }
    if (option === 'all') {
      this.getCategoryListing(0, '', '', '');
    } else {
      this.getCategoryListing(0, "", option, "")
    }
    this.checkValidate();
  }

  checkValidate() {
    if (this.categoryForm.status == 'VALID' && (this.selectCategory.valid || this.sortCategory.valid)) {
      this.selectCategoryError = false;
    } else {
      this.selectCategoryError = true;
    }
  }

  searchByCategory() {
    this.checkValidate();
    if (this.categoryForm.status == 'VALID' && (this.selectCategory.valid || this.sortCategory.valid)) {
      this.getCategoryListing(0, '', this.categoryForm.value.selectCategory == 'all' ? '' : this.categoryForm.value.selectCategory, this.categoryForm.value.sortCategory);
    } else if (this.categoryForm.status == 'INVALID') {
      this.selectCategoryError = true
    } else {
      this.selectCategoryError = false
      this.getCategoryListing(0, '', this.categoryForm.value.selectCategory == 'all' ? '' : this.categoryForm.value.selectCategory, '');
    }
  }

  goToArticleDetail(catId) {
    this.router.navigate(['/wiki-article-list/' + btoa(catId)])
    console.log(catId);

  }

  onChangeSorting(event) {
    this.checkValidate();
    if (this.categoryForm.status == 'VALID' && (this.selectCategory.valid || this.sortCategory.valid)) {
      this.getCategoryListing(0, '', this.categoryForm.value.selectCategory == 'all' ? '' : this.categoryForm.value.selectCategory, this.categoryForm.value.sortCategory);
    } else {
      this.selectCategoryError = false;
      this.getCategoryListing(0, '', '', this.categoryForm.value.sortCategory);
    }
  }

  wikiCategoryId() {
    this.dataService.wikiCategoryId.subscribe((res: any) => {
      this.wikiCatId = res.id
      if (res === 'all' || this.wikiCatId === undefined) {
        this.getCategoryListing(0, '', '', '');
      }
      else {
        this.getCategoryListing(0, '', this.wikiCatId, "");
      }
    })
  }

}
