import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { courseAssignedList, allCourses, courseDetail } from './../model/course';
import { Subject, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class CourseService {
  logUser = localStorage.getItem('loggedUser');
  jwt;

  constructor(private http: HttpClient, private apiService: ApiService) { }

  private _refreshNeeded$ = new Subject<void>();

  getUserAssignedCourses(data): Observable<courseAssignedList[]> {
    this.jwt = this.apiService.getJWT()
    if ((data.container_id > 0) || (data.container_id == undefined)) {
      return this.http.post<courseAssignedList[]>(this.apiService.getApiURL() + this.apiService.getCourse(), JSON.stringify(data),
        { headers: this.apiService.setHeaderValJWT(this.jwt) }
      ).pipe(
        map(
          (response) => {
            return response
          }
        )
      )
    } else {
      return null
    }

  }
  getCourseName(data) {
    this.jwt = this.apiService.getJWT();
    return this.http.post(this.apiService.getApiURL() + this.apiService.getCourse(), JSON.stringify(data),
      { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  getMarketPlace(data) {
    this.jwt = this.apiService.getJWT();
    return this.http.post(this.apiService.getApiURL() + this.apiService.getMarketPlace(), JSON.stringify(data),
      { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  getAllCourses(data): Observable<allCourses[]> {
    return this.http.post<allCourses[]>(this.apiService.getApiURL() + this.apiService.getContainer(), JSON.stringify(data), { headers: this.apiService.setHeaderVal() }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  getCourseDetail(data): Observable<courseDetail[]> {
    this.jwt = this.apiService.getJWT();
    return this.http.post<courseDetail[]>(this.apiService.getApiURL() + this.apiService.getCourse(), JSON.stringify(data),
      { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  getCourseCompletionValue(data) {
    this.jwt = this.apiService.getJWT()
    return this.http.post(this.apiService.getApiURL() + this.apiService.getCourse(), JSON.stringify(data),
      { headers: this.apiService.setHeaderValJWT(this.jwt) }
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    )
  }

  getModuleListByCourse(data) {
    this.jwt = this.apiService.getJWT()
    return this.http.post(this.apiService.getApiURL() + this.apiService.getCourse(), JSON.stringify(data),
      { headers: this.apiService.setHeaderValJWT(this.jwt) })
  }


  getAssesmentById(assesmentData) {
    this.jwt = this.apiService.getJWT();
    return this.http.post(this.apiService.getApiURL() + this.apiService.getAssesment(), JSON.stringify(assesmentData),
      { headers: this.apiService.setHeaderValJWT(this.jwt) })
  }


  getGlossaryDetailsById(glossaryData) {
    this.jwt = this.apiService.getJWT();
    return this.http.post(this.apiService.getApiURL() + this.apiService.getCourse(), glossaryData,
      { headers: this.apiService.setHeaderValJWT(this.jwt) }
    )
  }

  getCurrentCoursesTranscript(data){
    this.jwt = this.apiService.getJWT()
    if ((data.container_id > 0) || (data.container_id == undefined)) {
      return this.http.post(this.apiService.getApiURL() + this.apiService.getCourse(), JSON.stringify(data),
        { headers: this.apiService.setHeaderValJWT(this.jwt) }
      ).pipe(
        map(
          (response) => {
            return response
          }
        )
      )
    } else {
      return null
    }

  }


  getCourseDetailPreview(data): Observable<courseDetail[]> {
    return this.http.post<courseDetail[]>(this.apiService.getApiURL() + this.apiService.getCoursePreview(), JSON.stringify(data)
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    );
  }

}
