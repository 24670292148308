import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { AppComponent } from './app.component';
import {MatFormFieldModule} from '@angular/material/form-field';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { MaterialModule } from './material/material.module';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { RecaptchaModule } from 'ng-recaptcha';
// import { InputTrimModule } from 'ng2-trim-directive';
import { NgxPaginationModule } from 'ngx-pagination';
// import { NgxMaskModule } from 'ngx-mask';
// import { TextMaskModule } from 'angular2-text-mask';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
// import { DeviceDetectorModule } from 'ngx-device-detector';
// import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import {MatCardModule} from '@angular/material/card';
// import { PdfViewerModule } from 'ng2-pdf-viewer';
import { HTTP_INTERCEPTORS} from '@angular/common/http';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';


//modules
import { StreamModule } from './stream/stream.module';
import { SharedModule } from './shared/shared.module'

import { HostRoomTwoComponent } from './stream/component/host-room-two/host-room-two.component';
import { UserRoomTwoComponent } from './component/user-room-two/user-room-two.component';

//import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ImagekitioAngularModule } from 'imagekitio-angular';


import {
	 MainComponent,  FrontComponent,
	// tslint:disable-next-line: indent
	DashboardComponent, LoginComponent, SidebarComponent, ForgotPasswordComponent,
	NotFoundComponent, NewpasswordComponent, UserMessageComponent, CourseDetailsComponent, CourseComponent, CourseLayoutComponent,
	CourseSidebarComponent, ModuleDetailComponent, LessonDetailComponent, ActiveLinkBiding,
	GradbookTableComponent, GradebookStudentQuizResultComponent,
	// tslint:disable-next-line: indent
	GlossaryPopupComponent, PrivacyPolicyComponent, RemoveUserWarningComponent, ModuleOutcomeComponent, CourseOutcomeComponent , MarketplaceCourseListComponent , RedeemCourseComponent , NecessaryQuizComponent , LessonDetailsPreviewComponent , ActiveRoomDetailsComponent  , ExpiredRoomDetailsComponent  , WikiArticleDetailComponent , WikiArticleListComponent , WikiPollComponent , WikiSystemComponent  , WikiVideoPlayerComponent
} from './index';
import { AppRoutingModule } from './app-routing.module';

import { TruncatePipe } from './pipe/truncate.pipe';
import { Safe } from './pipe/safe-html.pipe';
import { StriphtmlPipe } from './pipe/striphtml.pipe';
import {ReplaceuploadPipe} from './pipe/replaceupload.pipe';
import {LineBreakPipe} from './pipe/line-break.pipe';

//material
import { DialogExampleComponent } from './dialogBox/dialog-example/dialog-example.component';
import { LessonCompletedDgBoxComponent } from './dialogBox/lesson-completed-dg-box/lesson-completed-dg-box.component';
import { PollComponent } from './component/poll/poll.component';
import { QuizComponent } from './component/quiz/quiz.component';


import { MessageListComponent } from './component/message-list/message-list.component';
import { NotificationListComponent } from './component/notification-list/notification-list.component';
import { MessageDetailComponent } from './component/message-detail/message-detail.component';
import { NotificationDetailComponent } from './component/notification-detail/notification-detail.component';
import { CompleteLessonDialogComponent } from './dialogBox/complete-lesson-dialog/complete-lesson-dialog.component';
import { SessionExpiredComponent } from './dialogBox/session-expired/session-expired.component';
import { LogoutConfirmationComponent } from './dialogBox/logout-confirmation/logout-confirmation.component';
import { ProfileComponent } from './component/profile/profile.component';
import { EditProfileComponent } from './component/edit-profile/edit-profile.component';
import { ProfileUpdateDgBoxComponent } from './dialogBox/profile-update-dg-box/profile-update-dg-box.component';
import { QuizMultipleComponent } from './component/quiz-multiple/quiz-multiple.component';
import { CourseSectionExpiredComponent } from './dialogBox/course-section-expired/course-section-expired.component';
import { GradeBookComponent } from './component/grade-book/grade-book.component';

import { TruncateTextPipe } from './pipe/truncatetext.pipe';
import { GeneralMessageComponent } from './dialogBox/general-message/general-message.component';
import { ChangeUserPasswordComponent } from './component/change-user-password/change-user-password.component';
import { AuthenticationInterceptor } from './guard/auth.interceptor';

import { DeleteNotificationComponent } from './dialogBox/delete-notification/delete-notification.component';
import { ShareLinkComponent } from './stream/dialog-box/share-link/share-link.component';

import { UserIdleModule } from 'angular-user-idle';

import { ContactUsComponent } from './component/contact-us/contact-us.component';

import { CookieService } from 'ngx-cookie-service';
import { AppService } from './service/app.service';
import { ConfirmLessonComponent } from './dialogBox/confirm-lesson/confirm-lesson.component';
import { DataProtectionComponent } from './component/data-protection/data-protection.component';
import { ImprintComponent } from './component/imprint/imprint.component';

import { VideoCategoryComponent } from './component/video/video-category/video-category.component';
import { VideoDetailComponent } from './component/video/video-detail/video-detail.component';
import { CategoryListVideoComponent } from './component/video/category-list-video/category-list-video.component';
import { CertificateQuizFormComponent } from './dialogBox/certificate-quiz-form/certificate-quiz-form.component';
import { PurchaseHistoryComponent } from './component/market-place/purchase-history/purchase-history.component';
import { CategoryDashboardComponent } from './component/category-dashboard/category-dashboard.component';
import { ViewAllCatCoursesComponent } from './component/view-all-cat-courses/view-all-cat-courses.component';
import { CourseDetailsPreviewComponent } from './component/course-preview/course-details-preview/course-details-preview.component';
import { PreviewLayoutComponent } from './layout/preview-layout/preview-layout.component';
import { ModuleDetailsPreviewComponent } from './component/course-preview/module-details-preview/module-details-preview.component';
import { UserRegistrationComponent } from './component/user-registration/user-registration.component';
import { SocketReconnectComponent } from './testing/socket-reconnect/socket-reconnect.component'
import { UserRoomSectionComponent } from './component/user-room-section/user-room-section.component';
import { HostSidebarComponent } from './layout/host-sidebar/host-sidebar.component';
import { UserRoomSectionTwoComponent } from './component/user-room-section-two/user-room-section-two.component';
import { ActiveRoomDetailsTwoComponent } from './component/active-rooms-details-two/active-room-details-two.component';
import { ScreenShareComponent } from './component/screen-share/screen-share.component';
import { ChatHistoryComponent } from './test/chat-history/chat-history.component';
import { StudentFeedbackComponent } from './component/student-feedback/student-feedback.component';
import { CreateFeedbackComponent } from './component/create-feedback/create-feedback.component';
import { WikiSidebarComponent } from './component/wiki/layout/wiki-sidebar/wiki-sidebar.component';
import { WikiLayoutComponent } from './component/wiki/layout/wiki-layout/wiki-layout.component';
import { SafeUrlPipe } from './pipe/safe-url.pipe';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { GeneralChatComponent } from './live-chat/component/general-chat/general-chat.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';  

import { UserChatTermsComponent } from './dialogBox/user-chat-terms/user-chat-terms.component';
import { CourseChatComponent } from './live-chat/component/course-chat/course-chat.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthGuard } from './guard/auth.guard';
import { CourseCertificateComponent } from './component/course-certificate/course-certificate.component';
import { ToastrModule } from 'ngx-toastr';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {MatExpansionModule} from '@angular/material/expansion';
import { LessonAvailableComponent } from './dialogBox/lesson-available/lesson-available.component';
import { NotPermissionComponent } from './dialogBox/not-permission/not-permission.component';



// import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    DashboardComponent,
    FrontComponent,
    LoginComponent,
    SidebarComponent,
    NotFoundComponent,
    ForgotPasswordComponent,
    NewpasswordComponent,
    UserMessageComponent,
    CourseDetailsComponent,
    ForgotPasswordComponent,
    CourseComponent,
    CourseLayoutComponent,
    CourseSidebarComponent,
    ModuleDetailComponent,
    LessonDetailComponent,
    QuizMultipleComponent,
    VideoCategoryComponent,
    CourseCertificateComponent,
    //pipes
    TruncatePipe,
    Safe,
    StriphtmlPipe,
    ReplaceuploadPipe,
    SafeUrlPipe,
    LineBreakPipe,
    DialogExampleComponent,
    LessonCompletedDgBoxComponent,
    LessonAvailableComponent,
    PollComponent,
    QuizComponent,
    MessageListComponent,
    NotificationListComponent,
    MessageDetailComponent,
    NotificationDetailComponent,
    CompleteLessonDialogComponent,
    SessionExpiredComponent,
    NotPermissionComponent,    
    LogoutConfirmationComponent,
    ProfileComponent,
    EditProfileComponent,
    ProfileUpdateDgBoxComponent,
    CourseSectionExpiredComponent,
    GradeBookComponent,

    ActiveLinkBiding,
    TruncateTextPipe,
    GradbookTableComponent,
    GeneralMessageComponent,
    ChangeUserPasswordComponent,
    GradebookStudentQuizResultComponent,
    GlossaryPopupComponent,
    PrivacyPolicyComponent,
    RemoveUserWarningComponent,
    DeleteNotificationComponent,
    ShareLinkComponent,
    

    //dialogBox

    ContactUsComponent,
    ModuleOutcomeComponent,
    CourseOutcomeComponent,
    ConfirmLessonComponent,
    DataProtectionComponent,
    ImprintComponent,


    VideoDetailComponent,
    CategoryListVideoComponent,
    CertificateQuizFormComponent,
    MarketplaceCourseListComponent,
    RedeemCourseComponent,
    PurchaseHistoryComponent,
    NecessaryQuizComponent,
    CategoryDashboardComponent,
    ViewAllCatCoursesComponent,
    CourseDetailsPreviewComponent,
    PreviewLayoutComponent,
    ModuleDetailsPreviewComponent,
    LessonDetailsPreviewComponent,
    UserRegistrationComponent,
    SocketReconnectComponent,

    ActiveRoomDetailsComponent,
    ActiveRoomDetailsTwoComponent,
    UserRoomSectionComponent,
    UserRoomSectionTwoComponent,

    ExpiredRoomDetailsComponent,
    HostSidebarComponent,

    HostRoomTwoComponent,
    UserRoomTwoComponent,
    ScreenShareComponent,
    ChatHistoryComponent,
    StudentFeedbackComponent,
    CreateFeedbackComponent,
    WikiSystemComponent,
    WikiArticleDetailComponent,
    WikiArticleListComponent,
    WikiSidebarComponent,
    WikiLayoutComponent,
    WikiVideoPlayerComponent,
    WikiPollComponent,
    GeneralChatComponent,
    UserChatTermsComponent,
    CourseChatComponent,
  ],
  
  imports: [
    // ImagekitioAngularModule.forRoot({
    //   publicKey: 'public_wvoQaUBswWVS0H9+hXsdzd0bNnQ=',
    //   urlEndpoint: "https://ik.imagekit.io/rescuedigital/",
    //   // authenticationEndpoint: "https://www-testing.retteracademy.com/"
    // }),
    BrowserModule,
    AppRoutingModule,
    RecaptchaModule,
    RouterModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatSelectModule,
    TranslateModule,
    ReactiveFormsModule,
    
    // MaterialModule,
    // InputTrimModule,
    NgxPaginationModule,
    MatDialogModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatButtonModule,
    // TextMaskModule,
    MatIconModule,
    CKEditorModule,
    ToastrModule.forRoot(),
    
		UserIdleModule.forRoot({ idle: 60, timeout: 5, ping: 5 }),
    StreamModule,
    SharedModule,
    NgxDocViewerModule,
    MatExpansionModule
    

  ],
  providers: [
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: "en-US" },
    JwtHelperService,
    CookieService,
    AppService,
    Title,
    AuthGuard

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
