import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from './api.service';
import { CommonService } from './../service/common.service';
import { Observable } from 'rxjs';
import { MatSnackBar, MatSnackBarConfig  } from '@angular/material/snack-bar';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  containerData: any;
  conainer_id: any;

  constructor(private http: HttpClient, private apiService: ApiService, private commonService: CommonService, private snackBar: MatSnackBar) { }
  public loggedInStatus: boolean = false;

  getAutoUserDetail(data) {
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getLoginRegister(), data,
      { headers: this.apiService.setHeaderVal() }
    )
  }

  login(loginDetails) {
    this.containerData = JSON.parse(localStorage.getItem('theme_style'))
    this.conainer_id = this.containerData ? this.containerData.id : '0';
    //  this.conainer_id = 1
    loginDetails['container_id'] = this.conainer_id
    return this.http.post(this.apiService.getApiURL() + this.apiService.getLoginRegister(), loginDetails, { headers: this.apiService.setHeaderVal() }
    )
  }

  verifyUser(email) {
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getLoginRegister(), email,
      { headers: this.apiService.setHeaderVal() }
    )
  }

  checkForgotPasswordUser(code) {
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getLoginRegister(), code,
      { headers: this.apiService.setHeaderVal() }
    )
  }

  setLoggenIn(value) {
    //  this.loggedInStatus = value;
    localStorage.setItem('loggedIn', value)
  }

  newPassword(data) {
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getLoginRegister(), data, { headers: this.apiService.setHeaderVal() }
    )
  }

  autoLogin(loginDetails) {
    this.containerData = JSON.parse(localStorage.getItem('theme_style'))
    this.conainer_id = this.containerData ? this.containerData.id : '0';
    //  this.conainer_id = 1
    // loginDetails['container_id'] = this.conainer_id
    return this.http.post(this.apiService.getApiURL() + this.apiService.getSwitchDomain(), loginDetails, { headers: this.apiService.setHeaderVal() }
    )
  }

  updateUserLoginStatus(details):Observable<object> {
    return this.http.post(
      this.apiService.getApiURL() + this.apiService.getSwitchDomain(), details, {
        headers: this.apiService.setHeaderVal()
    }
    )
  }

  checkRawToken(data):Observable<object>{
    return this.http.post(
      this.apiService.getApiURL()+this.apiService.getLoginRegister(), data , {
        headers: this.apiService.setHeaderVal()
      }
    )
  }


  openSnackBar(message: string, action?: string) {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    config.horizontalPosition = 'center'; // Center horizontally
    config.verticalPosition = 'top'; // Position at the top
    this.snackBar.open(message, action, config)
  }
}
