import {
  Component, OnInit, AfterViewInit, ViewChild, ViewContainerRef, ComponentRef,
  ComponentFactoryResolver, ComponentFactory,
  OnDestroy, Renderer2, ElementRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from './../../service/data.service';
import { SidebarService } from './../../service/sidebar.service';
import { LessonService } from './../../service/lesson.service';
import { ModuleService } from './../../service/module.service';
import { MatDialog } from '@angular/material/dialog';
import { CompleteLessonDialogComponent } from './../../dialogBox/complete-lesson-dialog/complete-lesson-dialog.component'
import { LessonCompletedDgBoxComponent } from './../../dialogBox/lesson-completed-dg-box/lesson-completed-dg-box.component'
import { PollService } from './../../service/poll.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { QuizService } from './../../service/quiz.service';
import { CommonService } from './../../service/common.service';
import { poll } from 'src/app/model/poll';
import { Subscription } from 'rxjs';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ApiService } from './../../service/api.service';
import { QuizMultipleComponent } from '../../component/quiz-multiple/quiz-multiple.component';

import { GlossaryPopupComponent } from '../../component/glossary-popup/glossary-popup.component';
import { GeneralMessageComponent } from './../../dialogBox/general-message/general-message.component';
import { ConfirmLessonComponent } from './../../dialogBox/confirm-lesson/confirm-lesson.component';
import { NecessaryQuizComponent } from './../../dialogBox/necessary-quiz/necessary-quiz.component';
import { StudentTimeService } from './../../service/student-time.service';

import { Transformation } from 'imagekit-javascript/dist/src/interfaces/Transformation';

@Component({
  selector: 'app-lesson-detail',
  templateUrl: './lesson-detail.component.html',
  styleUrls: ['./lesson-detail.component.scss'],
})

export class LessonDetailComponent implements OnInit, AfterViewInit, OnDestroy {

  isCompleted: boolean;

  lessonListPost: any;
  lessonId: any;
  lessonId2: any;
  lessonSlug : any ; 
  moduleId : any ; 

  sectionSlug: any;

  completeCourseSlug: any;
  completeModuleSlug: any;
  completeLessonSlug: any;

  lessonDetail: any;
  lessonLangData: any;
  completeLessonPostData: any;

  userId: any;

  dialogRef: any;


  pollDetails: any = null;
  pollDetailsOptions: any;
  poll_submited: any;
  lesson_poll_id: any;

  pollQuestionForm: FormGroup;
  loginForm: FormGroup;
  lessonName: string = '';

  poll_id: FormControl;
  pollexist: boolean = false

  poloption: FormControl
  results: any;

  optionStatus: boolean;

  email: FormControl;
  errorMessage: any;

  quizId: any;
  pollResultQues: any = [];

  getQuizIdPostData: any;

  isLoading: boolean = true;
  addPollData: any;

  nextLessonSlug: any;
  prevLessonSlug: any;

  lessonImg: any;
  watchVideoLink: string;
  watchVideoLinkk: any;

  currentLessonSlugContainer: Subscription;
  currentModuleSlugContainer: Subscription;
  routerLinkSubscription: Subscription;

  showIframe: boolean = false
  selectedIndex: any;
  nextModuleIndex: any;
  nextModuleSlug: any;
  nextModuleStatus: boolean = false;

  templateType: any;
  cykSubmitted: any;

  cykSubmittedData: any;
  submittedAnswerValues: any = [];
  sitePath: any;


  quizPanelStatus: boolean = false;
  quizContainer: boolean = false;
  quizcmpdata: any;
  courseSlug;
  lessonsQuizzes

  lessonInquireWords: any;
  readMoreStatus: any;
  launchURL: any;
  moduleSlug: any;
  quizIdSubject: Subscription;
  quizSubjectData: any;



  transcriptDataTitle: any;
  transcriptDataFile: any;

  routeParamValues: any;
  charLimit: number = 300;


  // Image Citation Data
  imageFileEx: any;
  matchElm: any[] = [];
  termRegEx: any;
  termResult: any;
  termHtmlData: any;
  termHtml: any;
  isLodingBtn: boolean = false;

  newLessonTempArr: { lesson_description: any, lesson_title: any }

  citationLicense: any;
  citationTotalRecord: any;
  pagingLimit: number = 20;
  pageCount: any[] = [];
  increasCount: number = 0;
  media_array_data: any;
  nextBtnLoading: boolean = false;
  dialogParam: any;

  showPanelStatus: false;
  // showTitle: boolean = true;

  necessaryModuleStatus: boolean;

  checkData: any;

  currentBtnThemeContainer: Subscription;
  buttonTheme: any;

  isLoadingPoll: Boolean;
  showLoadingPoll: Boolean = false;

  transformation: Array<Transformation> = [{
    height: "300",
    width: "300"
  }];

  /************************************** */
  @ViewChild("quizeDynamicComponent", { read: ViewContainerRef, static: true }) container;
  componentRef: ComponentRef<any>;

  @ViewChild("glossaryTermSection", { read: ViewContainerRef, static: true }) glossaryTermSectionView;
  glossaryTermSectionCMPRef: ComponentRef<any>;


  @ViewChild("readContentSection", { static: false }) readContentSection: ElementRef;
  @ViewChild("imageCitationDataPanel", { static: false }) imageCitationDataPanel: ElementRef;


  @ViewChild('videoPlayer', { static: false }) videoplayer: ElementRef;


  // tslint:disable-next-line: variable-name
  citation_array_data: any;
  citationArrayDataPanelStatus: boolean;
  mediaFileLocationPath: string;
  getInstituteUrl: any;
  lessonTemplates: any;
  firstLessonTemplate: void;
  modifiedLessonTemplate: void;
  j: number;
  count: number = 0
  showWatchPanel: boolean;
  showInquireSectionPanel: boolean;
  showpollPanel: boolean;
  showLessonResouesesPanel: boolean;
  showReadPanel: boolean;
  shoewexpandPanel: boolean;
  courseMenuArr: any;
  courseMenu: any;
  courseName: any;
  menuModuleArr: any = [];
  lastMenuArrIndex: number;
  courseOutCome: boolean;
  moduleOutCome: boolean;
  moduleOutComeDetail: any;
  courseOutcomeDetail: any;
  uploadVideoPath: string;
  videoPath: string;
  courseMenuLastIndex: any;
  lastMduleStatus: boolean;
  // tslint:disable-next-line: no-inferrable-types
  isLoadingNextLsnBtn: boolean = false;
  // tslint:disable-next-line: no-inferrable-types
  isLoadingNextModuleBtn: boolean = false;
  lessonConfirmStatus: boolean;
  paramslessonId: any;
  showInquireTitle: any;
  showWatchTitle: any;
  showReadTitle: any;
  showPollTitle: any;
  showExpandTitle: any;

  panelBgCol: any;
  panelTxtCol: any;
  necessaryQuiz: any;
  necessaryQuizId: any[] = [];
  necessaryQuizList: any;
  seconds: number;
  timestamp: string;
  timer: any;
  readSectionLength: any;
  readSecMultiImg: boolean;
  inquireSectionLength: any;
  inquireSecMultiImg: boolean;
  expandSectionLength: any;
  expandSecMultiImg: boolean;
  lessonSummarySectionLength: any;
  lessonSummarySecMultiImg: boolean;
  lessonToolboxSecMultiImg: boolean;
  lessonToolboxSectionLength: any;
  lessonCitationSectionLength: any;
  lessonCitationSecMultiImg: boolean;
  inquireSecImgStyle: boolean;
  readSecImgStyle: boolean;
  expandSecImgStyle: boolean;
  licenseCitImgStyle: boolean;
  lessonToolboxImgStyle: boolean;

  constructor(private router: Router, private route: ActivatedRoute, private fb: FormBuilder,
    private dataService: DataService, private lessonService: LessonService,
    private dialog: MatDialog, private pollService: PollService,
    private quizService: QuizService, private commonService: CommonService,
    private sanitizer: DomSanitizer, private apiService: ApiService,
    private resolver: ComponentFactoryResolver,
    private renderer: Renderer2, private moduleService: ModuleService, private sidebarService: SidebarService, private studentTimeService: StudentTimeService) {
    this.currentBtnThemeContainer = this.dataService.currentButtonTheme.subscribe(message => {
      this.buttonTheme = message;
    }, error => error);

    this.courseSlug = this.route.snapshot.paramMap.get('courseId');
    this.moduleSlug = this.route.snapshot.paramMap.get('moduleId');

    this.userId = this.commonService.getUserId();
    this.lessonId = this.route.snapshot.paramMap.get('lessonId');
    this.lessonId2 = this.route.snapshot.paramMap.get('lessonId');

    this.sectionSlug = this.route.snapshot.paramMap.get('sectionId');

    this.completeCourseSlug = this.route.snapshot.paramMap.get('courseId');
    this.completeModuleSlug = this.route.snapshot.paramMap.get('moduleId');
    this.completeLessonSlug = this.route.snapshot.paramMap.get('lessonId');

    this.dataService.changeLessonSlug(this.lessonId2)
    this.currentLessonSlugContainer = this.dataService.currentLessonSlug.subscribe(res => {
      this.lessonId = res['lesson_id'];
      this.lessonSlug = res['lesson_slug'];
      this.moduleId = res['module_id'];
    })

    // this.currentModuleSlugContainer = this.dataService.currentMessage.subscribe(message => {
    //   this.completeModuleSlug = message
    // })

    this.routerLinkSubscription = this.route.params.subscribe((res) => {
      if (!res['lessonId']) {
        this.targetWiseRoutePath('M')
      }
      this.paramslessonId = res['lessonId']
      this.router.navigate(['/courses/' + res['courseId'] + '/' + res['sectionId'] + '/' + res['moduleId'] + '/' + res['lessonId']])

      this.completeLessonSlug = res['lessonId'];
      this.charLimit = 300;
      const lessonElm = document.querySelector('.lessonCollapseContent');
      const lessonBtn = document.querySelector('.readMoreBtn .btn');
      if (lessonElm != null) {
        if (lessonElm.classList.contains('open')) {
          lessonElm.classList.remove('open');
          lessonBtn.innerHTML = 'Read More';
        }
      }
      this.getLessonDetail(res['lessonId']);

      this.getCourseMenu();

    });

    console.log(this.routerLinkSubscription);


    this.quizIdSubject = this.commonService.getQuizIdSubject.subscribe(
      data => {
        if (data['quiz_id']) {

          if (data['level_type'] == 3 && data['quiz_id']['quiz_reference_type'] != '7') {
            this.quizSubjectData = data['quiz_id'];
            setTimeout(() => {
              this.getQuizStartById(this.quizSubjectData['id'], this.quizSubjectData['title'], this.quizSubjectData['instruction'], null, null, null, null, this.quizSubjectData.quiz_reference_type, this.quizSubjectData.gradebook_section_type)
            }, 500)
          } else if (data['level_type'] == 3 && data['quiz_id']['quiz_reference_type'] == '7') {
            this.quizSubjectData = data['quiz_id'];
          } else {
          }
        }
      }
    );

  }

  toggleVideo(event: any) {
    this.videoplayer.nativeElement.play();
  }

  getPanelVisuals() {
    const siteDetail = this.commonService.getCurrentSiteDetail();
    this.panelBgCol = siteDetail.theme_style.panel_back_color;
    this.panelTxtCol = siteDetail.theme_style.panel_text_color;
  }

  startTimer() {
    this.seconds = 0;
    this.timer = setInterval(() => {
      const value = Math.abs(this.seconds);
      const days = Math.floor(value / 1440);
      const hours = Math.floor((value - (days * 1440)) / 3600);
      const min = Math.floor((value - (days * 1440) - (hours * 3600)) / 60);
      const sec = value - (days * 1440) - (hours * 3600) - (min * 60);
      // tslint:disable-next-line: max-line-length

      // tslint:disable-next-line: max-line-length
      this.timestamp = `${this.seconds < 0 ? '-' : ''}${days > 0 ? days + '.' : ''}${hours < 10 ? '0' + hours : hours}:${min < 10 ? '0' + min : min}:${sec < 10 ? '0' + sec : sec}`
      this.seconds++;
    }, 1000);
  }

  ngOnInit() {
    this.startTimer();
    this.getPanelVisuals();
    this.getInstituteUrl = this.apiService.getApiSitePath()
    this.sitePath = this.apiService.getSiteFolderPath();

    this.poll_submited = false;
    this.poll_id = this.fb.control('', Validators.required)
    this.poloption = this.fb.control('', Validators.required)

    this.pollQuestionForm = this.fb.group({
      poll_id: this.poll_id,
      poloption: this.poloption
    });

  }


  getCourseMenu() {
    const courseMenuPost = {
      method: 'getUserCourseActivityDetailForLesson',
      slug: this.courseSlug,
      id: this.userId,
      section_slug: this.sectionSlug,
      module_slug: this.completeModuleSlug
    }
    this.sidebarService.getCourseMenuDetail(courseMenuPost).subscribe(res => {

      if (!res['jwt_status']) {
        //this.commonService.openSessionExpireDialog()
      } else {
        this.moduleOutCome = (this.completeLessonSlug == res['data']['currentModuleLastLesson']?.slug) ? true : false;
        this.moduleOutComeDetail = res['data']['currentModuleOutcome']

        this.courseOutCome = (this.completeLessonSlug == res['data']['courseLastLesson']?.slug) ? true : false;
        this.courseOutcomeDetail = res['data']['courseOutcome']
      }

      //module order Code
      this.courseMenuArr = res['data']['modules']
      this.courseMenu = res['data']
      this.courseName = res['data']['course_name']

      if (this.courseMenuArr) {
        for (let i = 0; i < this.courseMenuArr.length; i++) {
          this.menuModuleArr[i] = this.courseMenuArr[i].slug
        }
        this.lessonService.courseMenu = this.menuModuleArr
        this.lastMenuArrIndex = this.menuModuleArr.length - 1
        this.lessonService.lastCourseModule = this.menuModuleArr[this.lastMenuArrIndex]
      }
    }, error => error);
  }

  submitDataForm() {
    $('#ltiLaunchForm').submit();
  }

  ngOnDestroy() {
    this.isLoading = true;
    this.nextModuleStatus = false;
    this.currentLessonSlugContainer.unsubscribe();
    // this.currentModuleSlugContainer.unsubscribe();
    this.routerLinkSubscription.unsubscribe();
    this.quizIdSubject.unsubscribe();
    this.currentBtnThemeContainer.unsubscribe();
  }


  /**
   * @description check poll options
   * @date 2019-09-16
   * @param {*} event
   * @memberof LessonDetailComponent
   */
  checkPollOptions(event: any) {
    var getAllTarget = event.target.parentElement.parentElement.parentElement.getElementsByTagName("div");
    for (var i = 0; i <= getAllTarget.length; i++) {
      event.target.parentElement.parentElement.parentElement.children[i].classList.remove('active');
    }

    if (event.target.checked) {
      event.target.parentElement.parentElement.classList.add('active')
    } else {
      event.target.parentElement.parentElement.classList.remove('active')
    }

  }

  ngAfterViewInit() {
    if (this.quizSubjectData) {
      if (this.quizSubjectData.quiz_reference_type != '7') {
        setTimeout(() => {
          this.getQuizStartById(this.quizSubjectData['id'], this.quizSubjectData['title'], this.quizSubjectData['instruction'], null, null, null, null, this.quizSubjectData.quiz_reference_type, this.quizSubjectData.gradebook_section_type)
        }, 1000)
      }
    }

  }

  /**
   * @description submission of poll result
   * @date 2019-09-16
   * @param {*} event
   * @memberof LessonDetailComponent
   */
  addPoll(event) {
    event.preventDefault()
    if (this.poloption.status == 'VALID') {
      this.showLoadingPoll = true
      this.isLoadingPoll = true;
      this.addPollData = {
        id: this.commonService.getUserId(),
        method: 'courseActivityCompletionMethod',
        course_slug: this.completeCourseSlug,
        module_slug: this.completeModuleSlug,
        lesson_slug: this.lessonId,
        section_slug: this.sectionSlug,
        contain_id: this.pollQuestionForm.value.poll_id,
        contain_type: this.commonService.containTypeArr['poll'],
        contain_answer: this.pollQuestionForm.value.poloption,
        container_id: this.commonService.getDomainContainerId()
      }

      this.pollService.savePollResult(this.addPollData).subscribe(res => {
        this.poll_submited = true;
        this.getResultCount(this.pollQuestionForm.value.poll_id);

      });
    } else {
      this.errorMessage = 'Please select atleast one option';
    }

  }

  /**
   * @description get poll result count
   * @date 2019-09-16
   * @param {*} poll_id
   * @memberof LessonDetailComponent
   */
  getResultCount(poll_id) {
    if (poll_id > 0) {
      this.pollService.getPollCount(poll_id).subscribe(res => {
        if (res) {
          this.results = res['data'];
          if (this.results[0] != undefined) {
            this.pollResultQues = this.results[0].question
          }
        }
        this.showLoadingPoll = false;
        this.isLoadingPoll = false;
      });
    }
  }


  /**
   * @description check if poll is submitted
   * @date 2019-09-16
   * @param {*} poll_id
   * @param {*} userId
   * @memberof LessonDetailComponent
   */
  checkPollSubmited(poll_id, userId) {
    this.pollService.checkPollSubmited(poll_id, userId).subscribe(res => {
      if (res['status'] == true) {
        this.poll_submited = true;
      } else {
        this.poll_submited = false;
      }
    });
  }


  openConfirmLessonDialogBox(target: any) {

    this.routeParamValues = this.routeParams();
    const data = {
      id: this.userId,
      method: 'checkIfLessonIsAlreadyCompleted',
      course_slug: this.routeParamValues[0].courseSlug,
      module_slug: this.routeParamValues[0].moduleSlug,
      section_slug: this.sectionSlug,
      lesson_slug: this.routeParamValues[0].lessonSlug,
      container_id: this.commonService.getDomainContainerId()
    };
    this.lessonService.checkIfLessonAlreadyCompleted(data).subscribe((res) => {
      // this.isLoadingNextLsnBtn = true;
      if (!res['status']) {
        this.dialogRef = this.dialog.open(ConfirmLessonComponent, {
        });
        this.dialogRef.afterClosed().subscribe((res) => {
          if (res === 'true') {
            // tslint:disable-next-line: max-line-length
            this.getCompleteLesson(this.routeParamValues[0].courseSlug, this.routeParamValues[0].moduleSlug, this.routeParamValues[0].lessonSlug, true, target);
          } else {
            // this.targetWiseRoutePath(target);
            this.isLoadingNextLsnBtn = false;
            this.isLoadingNextModuleBtn = false;
            console.log(this.isLoadingNextModuleBtn);
            
          }
        });
      } else {
        this.targetWiseRoutePath(target);
      }

    });
  }

  /**
   * @description get lesson detail by lesson slug
   * @date 2019-09-16
   * @param {*} lessonId
   * @memberof LessonDetailComponent
   */
  getLessonDetail(lessonId) {
    this.moduleSlug = this.route.snapshot.paramMap.get('moduleId')
    this.lessonListPost = {
      id: this.userId,
      user_id: this.commonService.getUserId(),
      lessonSlug: lessonId,
      courseSlug: this.courseSlug,
      moduleSlug: this.moduleSlug,
      sectionSlug: this.sectionSlug,
      container_id: this.commonService.getDomainContainerId(),
      method: 'getLessonByLessonSlug'
    };

    this.isLoading = true;
    this.lessonService.getLessonList(this.lessonListPost).subscribe(res => {
      if (!res['jwt_status']) {
        this.commonService.openSessionExpireDialog()
      } else {
        if (res['status']) {
          this.isLoading = false;
          this.lessonDetail = res['data'];

          this.lessonLangData = res['data'].lession_lang_data

          // if(localStorage.getItem('locale') == 'ger') {
          //   this.lessonDetail.name = this.lessonLangData[0].name
          //   this.lessonDetail.inquire_section_title  = this.lessonLangData[0].inquire_section_title
          //   this.lessonDetail.inquire_section  = this.lessonLangData[0].inquire_section
          //   // this.lessonDetail.watch_title  = this.lessonLangData[0].watch_title
          //   this.lessonDetail.read_section_title  = this.lessonLangData[0].read_section_title
          //   this.lessonDetail.expand_title  = this.lessonLangData[0].expand_title
          //   this.lessonDetail.expand_description  = this.lessonLangData[0].expand_description
          //   this.lessonDetail.practice_description  = this.lessonLangData[0].practice_description
          //   this.lessonDetail.summary  = this.lessonLangData[0].summary
          //   this.lessonDetail.lesson_toolbox  = this.lessonLangData[0].lesson_toolbox
          //   this.lessonDetail.license_citation  = this.lessonLangData[0].license_citation
          // } else {
          //   if(this.lessonLangData[1]) {

          //     this.lessonDetail.name = this.lessonLangData[1].name
          //     this.lessonDetail.inquire_section_title  = this.lessonLangData[1].inquire_section_title
          //     this.lessonDetail.inquire_section  = this.lessonLangData[1].inquire_section
          //     // this.lessonDetail.watch_title  = this.lessonLangData[1].watch_title
          //     this.lessonDetail.read_section_title  = this.lessonLangData[1].read_section_title
          //     this.lessonDetail.expand_title  = this.lessonLangData[1].expand_title
          //     this.lessonDetail.expand_description  = this.lessonLangData[1].expand_description
          //     this.lessonDetail.practice_description  = this.lessonLangData[1].practice_description
          //     this.lessonDetail.summary  = this.lessonLangData[1].summary
          //     this.lessonDetail.lesson_toolbox  = this.lessonLangData[1].lesson_toolbox
          //     this.lessonDetail.license_citation  = this.lessonLangData[1].license_citation

          //   } else {
          //     this.lessonDetail.name = this.lessonLangData[0].name
          //   this.lessonDetail.inquire_section_title  = this.lessonLangData[0].inquire_section_title
          //   this.lessonDetail.inquire_section  = this.lessonLangData[0].inquire_section
          //   // this.lessonDetail.watch_title  = this.lessonLangData[0].watch_title
          //   this.lessonDetail.read_section_title  = this.lessonLangData[0].read_section_title
          //   this.lessonDetail.expand_title  = this.lessonLangData[0].expand_title
          //   this.lessonDetail.expand_description  = this.lessonLangData[0].expand_description
          //   this.lessonDetail.practice_description  = this.lessonLangData[0].practice_description
          //   this.lessonDetail.summary  = this.lessonLangData[0].summary
          //   this.lessonDetail.lesson_toolbox  = this.lessonLangData[0].lesson_toolbox
          //   this.lessonDetail.license_citation  = this.lessonLangData[0].license_citation
          //   }
          // }
          if (this.lessonDetail != undefined) {
            // Read Section Img
            this.readSectionLength = (this.lessonDetail.read_section.match(/img/g) || []).length;
            this.readSecMultiImg = (this.readSectionLength > 1) ? true : false;

            // Inquire Section Img
            this.inquireSectionLength = (this.lessonDetail.inquire_section.match(/img/g) || []).length;
            this.inquireSecMultiImg = (this.inquireSectionLength > 1) ? true : false;

            // Expand Section Img
            this.expandSectionLength = (this.lessonDetail.expand_description.match(/img/g) || []).length;
            this.expandSecMultiImg = (this.expandSectionLength > 1) ? true : false;

            // Lesson Summary Section Img
            this.lessonSummarySectionLength = (this.lessonDetail.summary.match(/img/g) || []).length;
            this.lessonSummarySecMultiImg = (this.lessonSummarySectionLength > 1) ? true : false;


            // Lesson Summary Section Img
            this.lessonToolboxSectionLength = (this.lessonDetail.lesson_toolbox.match(/img/g) || []).length;
            this.lessonToolboxSecMultiImg = (this.lessonToolboxSectionLength > 1) ? true : false;

            // Lesson Summary Section Img
            this.lessonCitationSectionLength = (this.lessonDetail.license_citation.match(/img/g) || []).length;
            this.lessonCitationSecMultiImg = (this.lessonCitationSectionLength > 1) ? true : false;

            this.lessonConfirmStatus = (this.lessonDetail.lesson_confirm == 1) ? true : false;
            //this.showPanelStatus = (this.lessonDetail['is_open_text'] == 1) ? false : true;
            this.resetPanelCollapse();

            this.showInquireTitle = (this.lessonDetail.inquire_section_hide == 0) ? true : false;
            this.showReadTitle = (this.lessonDetail.read_section_hide == 0) ? true : false;
            this.showWatchTitle = (this.lessonDetail.watch_section_hide == 0) ? true : false;
            this.showExpandTitle = (this.lessonDetail.expand_section_hide == 0) ? true : false;
            this.showPollTitle = (this.lessonDetail.reflect_section_hide == 0) ? true : false;

            this.inquireSecImgStyle = (this.lessonDetail.inquire_section_img_style == 1) ? true : false;
            this.readSecImgStyle = (this.lessonDetail.read_section_img_style == 1) ? true : false;
            this.expandSecImgStyle = (this.lessonDetail.expand_img_style == 1) ? true : false;
            this.lessonToolboxImgStyle = (this.lessonDetail.lesson_toolbox_img_style == 1) ? true : false;
            this.licenseCitImgStyle = (this.lessonDetail.license_cit_img_style == 1) ? true : false;

            // this.showTitle = (this.lessonDetail.is_hide_title == 0) ? true : false;

            if ((this.lessonDetail.watch_video_file != undefined) || (this.lessonDetail.watch_video_file != null)) {
              this.lessonDetail.watch_video_file = this.lessonDetail.watch_video_file.replace('uploads/', '');
              console.log(this.lessonDetail.watch_video_file);
              
            }

            // tslint:disable-next-line: max-line-length
            this.uploadVideoPath = (this.lessonDetail.watch_video_file) ? "https://static-videos.weberrescueacademy.com/" + this.lessonDetail.watch_video_file.replace("media_images/", "") : '';

            this.showWatchPanel = (this.lessonDetail.watch_title != '') ? true : false;

            this.showInquireSectionPanel = (this.lessonDetail.inquire_section_title != '') ? true : false;
            this.showReadPanel = (this.lessonDetail.read_section_title != '') ? true : false;
            this.showpollPanel = (this.lessonDetail.poll_id != null) ? true : false;
            this.isLoadingPoll = (this.lessonDetail.poll_id != null) ? true : false;
            this.shoewexpandPanel = (this.lessonDetail.expand_title != '') ? true : false;

            if ((this.lessonDetail.lesson_toolbox == '') && (this.lessonDetail.license_citation == '')) {
              this.showLessonResouesesPanel = false;
            } else {
              this.showLessonResouesesPanel = true;
            }


            //read more
            this.lessonInquireWords = this.lessonDetail.inquire_section.split('').length;

            if (this.lessonInquireWords > 300) {
              this.readMoreStatus = true;
            } else {
              this.readMoreStatus = false;
            }


            this.lessonName = this.lessonDetail['name'];

            localStorage.setItem('lessonCYK', this.lessonDetail.quiz_id);

            this.quizService.changeQuizId(this.lessonDetail.quiz_id);

            this.quizService.quizType = this.commonService.quizTypeArr['CYK'];

            this.isLoading = false;
            this.lesson_poll_id = this.lessonDetail.poll_id;

            this.lessonImg = this.lessonDetail.lesson_img != 'undefined' ? this.lessonDetail.lesson_img : null;
            this.watchVideoLink = this.lessonDetail.watch_video_link;
            console.log(this.watchVideoLink);
            

            this.watchVideoLinkk = this.sanitizer.bypassSecurityTrustResourceUrl(this.watchVideoLink);
            console.log(this.watchVideoLinkk);
            

            this.transcriptDataTitle = this.lessonDetail.watch_video_transcript;
            this.transcriptDataFile = this.lessonDetail.watch_video_file;
            this.templateType = this.lessonDetail.template_type;
            this.lessonTemplates = res['data'].lesson_templates;

            if (res['data'].quiz_data.length > 0) {
              this.quizPanelStatus = true;
              this.lessonsQuizzes = res['data'].quiz_data;

            } else {
              this.quizPanelStatus = false;
            }
            this.increasCount = 0;
            this.pageCount = [];

            this.citation_array_data = res['data'].citation_data;
            this.citationTotalRecord = res['data'].citation_data.length;
            if (this.citationTotalRecord > 0) {
              const roundOffCount = Math.ceil(this.citationTotalRecord / this.pagingLimit)

              for (let i = 1; i <= roundOffCount; i++) {
                const pageItenData = {
                  pageItem: this.increasCount
                }
                this.pageCount.push(pageItenData)
                this.increasCount = this.increasCount + 20;
              }
              this.getDataById(this.pageCount[0].pageItem);
            } else {
              this.media_array_data = res['data'].citation_data
            }

            if (this.citation_array_data.length > 0) {
              this.citationArrayDataPanelStatus = true
            } else {
              this.citationArrayDataPanelStatus = false
            }
            setTimeout(() => {
              this.termHtml = this.lessonDetail.read_section;
            }, 100);
            setTimeout(() => {
              //this.readContentSection.nativeElement.innerHTML = this.lessonDetail.read_section
              this.getGlossaryData(this.lessonDetail)
            }, 200)
          }
          if (this.componentRef != undefined) {
            this.componentRef.destroy()
          }

          this.nextLessonSlug = this.lessonDetail.next_lesson_slug
          this.prevLessonSlug = this.lessonDetail.previous_lesson_slug
          if (!this.nextLessonSlug) {
            this.completeModuleSlug = this.route.snapshot.paramMap.get('moduleId')
            this.courseMenuLastIndex = this.lessonService.courseMenu.length - 1;
            this.selectedIndex = this.lessonService.courseMenu.findIndex(item => item == this.completeModuleSlug);
            this.lastMduleStatus = (this.selectedIndex == this.courseMenuLastIndex) ? true : false;
            this.nextModuleIndex = this.selectedIndex + 1;
            this.nextModuleSlug = this.lessonService.courseMenu[this.nextModuleIndex];
            this.nextModuleStatus = this.nextModuleSlug ? true : false;
          } else {
            this.nextModuleStatus = false;
          }

          this.isCompleted = this.lessonDetail.isCompleted;

          

          if (this.lesson_poll_id > 0) {
            this.getPollDetails(this.lesson_poll_id);
            this.getResultCount(this.lesson_poll_id);
            this.checkPollSubmited(this.lesson_poll_id, this.userId);
          } else {
            this.pollexist = false;
            this.pollDetails = null;
          }


          this.cykSubmitted = { cyk_submitted: this.lessonDetail.cyk_submitted, cyk_time: res['data']['cyk_quiz_spent_time'] };

          this.quizService.changeCykSubmittedStatus(this.cykSubmitted);

          this.necessaryQuizData();
          this.necessaryQuizPostData();

        } else {
          this.commonService.redirectToLogin();
        }
      }
    }, error => error);
  }

  necessaryQuizData() {
    const selectedIndex = this.lessonDetail.necessary_quiz.findIndex(item => item.comp_status == 1);
    this.necessaryQuiz = this.lessonDetail.necessary_quiz.filter(item => item.comp_status == 0);
    // this.lessonDetail.necessary_quiz.forEach((element: any) => {
    //   if (element.comp_status == 0) {
    //     this.necessaryQuizId.push(element.quiz_id);
    //   }
    // });
    // console.log("necessory quiz id", this.necessaryQuizId);

    console.log(this.necessaryQuiz);
    this.necessaryQuizList = this.lessonDetail.necessary_quiz_list;
  }

  getDataById(data: any) {
    this.media_array_data = this.citation_array_data.slice(data, data + this.pagingLimit);
    if (this.imageCitationDataPanel != undefined) {
      this.imageCitationDataPanel.nativeElement.scrollIntoView();
    }
    setTimeout(() => {
      const allPageElm = document.querySelectorAll('.pagination .page-item.active');
      if (allPageElm.length > 0) {
        this.renderer.removeClass(allPageElm[0], 'active');
      }
      const pagingElement = document.getElementById('paging_link_' + data)
      this.renderer.addClass(pagingElement, 'active')
    }, 100)
  }
  /**
   * @description Find term and add element for each
   * @date 2019-10-25
   * @memberof LessonDetailComponent
   */
  getGlossaryData(lessonDetail) {

    for (let i = 0; i < lessonDetail.glossary_data.length; i++) {
      setTimeout(() => {
        if (lessonDetail.glossary_data[i].avaibility == 2) {
          const termParam = this.lessonDetail.glossary_data[i].term;
          this.termRegEx = new RegExp(termParam, 'g');
          if (this.readContentSection != undefined) {
            this.matchElm = this.termHtml.match(this.termRegEx);

            if (this.matchElm != null) {
              const termRegEx = new RegExp(this.matchElm[0], 'g');
              const matchElm = this.termHtml.match(termRegEx);
              const index = this.termHtml.indexOf(this.matchElm[0])
              const indexLength = this.termHtml.slice(0, index + this.matchElm[0].length);
              const htmlContent = indexLength.replace(this.termRegEx, '<span class="highlight"><a class="glossary_term" id="' + this.lessonDetail.glossary_data[i].id + '">' + matchElm[0] + '</a></span>', index);
              this.termHtml = htmlContent + this.termHtml.slice(index + this.matchElm[0].length, -1);
            }
          }
        } else {
          const termParam = this.lessonDetail.glossary_data[i].term;
          this.termRegEx = new RegExp(termParam, 'g');
          if (this.readContentSection != undefined) {
            this.matchElm = this.termHtml.match(this.termRegEx);

            if (this.matchElm != null) {
              const termRegEx = new RegExp(this.matchElm[0], 'g');
              const matchElm = this.termHtml.match(termRegEx);

              for (let j = 0; j < this.matchElm.length; j++) {
                const termRegEx = new RegExp(this.matchElm[j], 'g');
                const matchElm = this.termHtml.match(termRegEx);
                this.termResult = this.termHtml.replace(
                  this.termRegEx,
                  '<span class="highlight"><a class="glossary_term" id="' + this.lessonDetail.glossary_data[i].id + '">' + matchElm[j] + '</a></span>'
                );

                this.termHtml = this.termResult
              }

            }
          }
        }
      }, 10)

    }
    setTimeout(() => {
      if (this.readContentSection != undefined) {
        this.readContentSection.nativeElement.innerHTML = this.termHtml;
      } else {
        //this.renderer.setProperty(this.readContentSection.nativeElement, 'innerHTML', this.termHtml)
      }
    }, 100);

    setTimeout(() => {
      const emptyElm = document.getElementsByClassName('glossary_term')
      for (let i = 0; i < emptyElm.length; i++) {
        if (emptyElm[i].childElementCount > 0) {
          emptyElm[i].childNodes[0].remove()
        }
      }
    }, 150)

    setTimeout(() => this.getGlossaryPopoverSection(this.resolver, this.glossaryTermSectionCMPRef, this.glossaryTermSectionView), 100);

  }
  /**
   * @description Open inline dialog for each term
   * @date 2019-10-25
   * @param {*} resolver, glossaryTermSectionCMPRef, glossaryTermSectionView
   * @memberof LessonDetailComponent
   */
  getGlossaryPopoverSection(resolver, glossaryTermSectionCMPRef, glossaryTermSectionView) {
    const glossaryTerm = document.getElementsByClassName('glossary_term');
    for (let o = 0; o < glossaryTerm.length; o++) {
      if (glossaryTerm[o].childNodes.length == 1) {
        glossaryTerm[o].addEventListener("click", function (event) {
          glossaryTermSectionView.clear()
          if (glossaryTermSectionCMPRef != undefined) {
            glossaryTermSectionCMPRef.destroy()
          }
          const eventData = {
            id: event.target['id'],
            top: event['pageY'],
            left: event['layerX']
          }
          const factory: ComponentFactory<any> = resolver.resolveComponentFactory(GlossaryPopupComponent);
          glossaryTermSectionCMPRef = glossaryTermSectionView.createComponent(factory);
          glossaryTermSectionCMPRef.instance.type = eventData;
          glossaryTermSectionCMPRef.instance.output.subscribe((res) => {
            if (res['closed'] == true) {
              glossaryTermSectionCMPRef.destroy()
            }
          });

        });
      }
    }
  }


  /**
   * @description get poll detail by poll id
   * @date 2019-09-16
   * @param {*} poll
   * @memberof LessonDetailComponent
   */
  getPollDetails(poll) {

    this.pollService.getPollByid(poll).subscribe(res => {
      this.pollDetails = res['data'];

      res['optionStatus'] == 1 ? this.pollexist = true : this.pollexist = false
      this.pollDetailsOptions = this.pollDetails.opts;
      this.optionStatus = res['optionStatus']
    },
      error => error);
  }


  /**
   * @description completing lesson process
   * @date 2019-09-16
   * @param {*} courseSlug
   * @param {*} moduleSlug
   * @param {*} lessonSlug
   * @memberof LessonDetailComponent
   */
  getCompleteLesson(courseSlug: any, moduleSlug: any, lessonSlug: any, routeStatus: boolean, target: any) {
    this.completeLessonPostData = {
      id: this.userId,
      method: 'completeLesson',
      course_slug: courseSlug,
      module_slug: moduleSlug,
      section_slug: this.sectionSlug,
      lesson_slug: lessonSlug,
      container_id: this.commonService.getDomainContainerId()
    }
    this.lessonService.getCompleteLesson(this.completeLessonPostData).subscribe(res => {
      // tslint:disable-next-line: no-string-literal
      if (res['status']) {
        this.isLodingBtn = false;
        this.isLoadingNextLsnBtn = false;
        this.isLoadingNextModuleBtn = false;
        // tslint:disable-next-line: no-string-literal
        this.isCompleted = res['status'];

        // if (routeStatus) {
        //   this.targetWiseRoutePath(target);
        // }

        const cmpdata = {
          lesson_slug: lessonSlug,
          lesson_id: res['lesson_id'],
          module_id: res['module_id']
        }
        this.dataService.changeLessonSlug(cmpdata);
      } else {
        let errmsg: any;
        if (localStorage.getItem('locale') == 'ger') {
          errmsg = 'Fehler !';
        } else {
          errmsg = 'Error !';
        }

        this.isLodingBtn = false;
        this.dialogParam = {
          redirect: false,
          message: errmsg
        }
        this.commonService.openCourseSectionExpireDialog(this.dialogParam);
      }
    });

  }

  studentLessonTimeSaving() {
    const data = {
      id: this.commonService.getUserId(),
      method: 'savingStudentTime',
      container_id: this.commonService.getDomainContainerId(),
      course_slug: this.courseSlug,
      section_slug: this.sectionSlug,
      module_slug: this.moduleSlug,
      lesson_slug: this.lessonSlug,
      lesson_id : this.lessonId,
      module_id : this.moduleId,
      lesson_time: this.timestamp,
      page: 'L'
    };  

    console.log("student saving time ",data);
    

    this.studentTimeService.saveStudentTime(data).subscribe(res => {
    });
    clearInterval(this.timer);
  }

  targetWiseRoutePath(target: any) {
    if (target === 'L') {
      this.isLoadingNextLsnBtn = false;

      this.studentLessonTimeSaving();

      // tslint:disable-next-line: max-line-length
      this.commonService.routeToLessonDetail(this.routeParamValues[0].courseSlug, this.sectionSlug, this.routeParamValues[0].moduleSlug, this.routeParamValues[0].nextLessonSlug);
    } else if (target === 'M') {
      this.isLoadingNextModuleBtn = false;
      this.studentLessonTimeSaving();
      console.log("target wise route path");
      
      // tslint:disable-next-line: max-line-length
      this.router.navigate(['/module-outcome/' + this.routeParamValues[0].courseSlug + '/' + this.sectionSlug + '/' + this.routeParamValues[0].moduleSlug]);
    }
  }

  /**
   * @description open already completed lesson dialog box
   * @date 2019-09-16

   */
  openAlreadyCompletedLessonDialog() {
    this.dialogRef = this.dialog.open(LessonCompletedDgBoxComponent);
  }

  toggleContainer(event) {
    event.target.parentElement.parentElement.parentElement.classList.toggle('open');
    // tslint:disable-next-line: prefer-const
    let parentElm = event.target.parentElement.parentElement.parentElement;
    if (parentElm.className === 'lessonCollapseContent open') {
      event.target.innerText = 'Less';
      this.charLimit = 5000000;
    } else {
      event.target.innerText = 'Read More';
      this.charLimit = 300;
    }
  }

  completeButton() {
    // this.necessaryQuizService(this.checkData, 'C');
    this.openCompleteLessonDialog()
  }

  /**
   * @description open complete lesson dialog box
   * @date 2019-09-16
   */
  openCompleteLessonDialog() {
    this.routeParamValues = this.routeParams();
    this.dialogRef = this.dialog.open(CompleteLessonDialogComponent, {
      disableClose: true
    });
    this.isLodingBtn = true;
    this.dialogRef.afterClosed().subscribe(res => {
      if (res === 'true') {
        // tslint:disable-next-line: max-line-length
        this.getCompleteLesson(this.routeParamValues[0].courseSlug, this.routeParamValues[0].moduleSlug, this.routeParamValues[0].lessonSlug, false, null);
      } else {
        this.isLodingBtn = false;
        this.isLoadingNextLsnBtn = false; 
        this.isLoadingNextModuleBtn = false;
      }
    });
  }

  /**
   * @description go to previos lesson page within same course
   * @date 2019-09-16
   */
  previousLessonClick() {
    this.routeParamValues = this.routeParams()
    this.nextModuleStatus = false;
    this.studentLessonTimeSaving();
    console.log("previous lesson click");
    
    // tslint:disable-next-line: max-line-length
    this.commonService.routeToLessonDetail(this.routeParamValues[0].courseSlug, this.sectionSlug, this.routeParamValues[0].moduleSlug, this.routeParamValues[0].prevLessonSlug);
  }


  /**
   * @description go to next lesson page within same course
   * @date 2019-09-16
   */
  nextLessonClick() {
    this.isLoadingNextLsnBtn = true;
    console.log(this.isCompleted);
    
    this.necessaryQuizService(this.checkData, 'L');
    // this.openConfirmLessonDialogBox('L');
  }

  nextLessonClickOld() {
    this.routeParamValues = this.routeParams();
    this.studentLessonTimeSaving();
    console.log("studentLessonTimeSaving");
    
    //this.necessaryQuizService(this.checkData, 'L');
    this.commonService.routeToLessonDetail(this.routeParamValues[0].courseSlug, this.sectionSlug, this.routeParamValues[0].moduleSlug, this.routeParamValues[0].nextLessonSlug);
  }

  necessaryQuizService(checkData, target) {
    this.routeParamValues = this.routeParams();
    this.lessonService.checkNecessaryQuizActivity(checkData).subscribe(res => {
      if (res['status']) {
        if (target == 'L') {
          if ((this.lessonConfirmStatus) && (!this.isCompleted)) {
            this.openConfirmLessonDialogBox('L');
          } else {
            this.studentLessonTimeSaving();
            console.log('necessary quiz service');
            
            // tslint:disable-next-line: max-line-length
            this.commonService.routeToLessonDetail(this.routeParamValues[0].courseSlug, this.sectionSlug, this.routeParamValues[0].moduleSlug, this.routeParamValues[0].nextLessonSlug);
          }
        } else if (target == 'C') {
          this.openCompleteLessonDialog();
        }
      } else {
        if(!this.isCompleted){
          this.openCompleteLessonDialog()
        }
        else{
          this.openNecessaryDialogBox();
        }
      }
    });
  }

  necessaryQuizPostData() {
    this.checkData = {
      id: this.commonService.getUserId(),
      course_slug: this.courseSlug,
      section_slug: this.sectionSlug,
      module_slug: this.moduleSlug,
      lesson_slug: this.completeLessonSlug,
      quiz_data: this.lessonDetail.necessary_quiz_list,
      method: 'checkNecessaryQuizActivity'
    };
  }




  openNecessaryDialogBox() {
    this.dialogRef = this.dialog.open(NecessaryQuizComponent, {
      //	height: '40%',
      width: 'auto',
      data: {
        quiz_data: this.necessaryQuiz,
      }
    });

    this.dialogRef.afterClosed().subscribe(res => {
      this.isLoadingNextModuleBtn = true;
      if (res == 'true') {
        this.isLoadingNextModuleBtn = false;
        this.isLoadingNextLsnBtn = false;
      }
    })
  }

  nextModuleClick() {
    this.isLoadingNextModuleBtn = true;

    console.log("next module click", this.nextLessonSlug);
    console.log(this.necessaryQuiz.length);
    console.log(this.paramslessonId);
    if (this.necessaryQuiz.length > 0) {
      this.necessaryQuizService(this.checkData, 'L');
    } else if (this.nextLessonSlug == null) {
      this.openConfirmLessonDialogBox('M');
    } else {
      this.openConfirmLessonDialogBox('M');
    }
  }


  /**
   * @description common route params to be used for navigation to lesson and module detail page
   * @date 2019-09-16
   */
  routeParams() {
    const paramArr: Array<{ courseSlug: any, moduleSlug: any, lessonSlug: any, nextLessonSlug: any, prevLessonSlug: any }> = [];
    this.completeCourseSlug = this.route.snapshot.paramMap.get('courseId');
    this.completeModuleSlug = this.route.snapshot.paramMap.get('moduleId');
    this.completeLessonSlug = this.route.snapshot.paramMap.get('lessonId');
    paramArr.push({
      courseSlug: this.completeCourseSlug, moduleSlug: this.completeModuleSlug, lessonSlug: this.completeLessonSlug,
      nextLessonSlug: this.nextLessonSlug, prevLessonSlug: this.prevLessonSlug
    })
    return paramArr
  }


  /**************************************/
  /****  Create Dynamic Component   */

  getQuizStartById(quizeId: any, quizTitle: string, quizItemInstruction: any, isCompleted: null, attempts?: null, view_btn?: null, quiz_time?: null, quiz_ref_type?: any, gradebook_section_type?: any) {


    const course_slug_id = this.route.snapshot.paramMap.get('courseId')
    const lesson_slug_id = this.route.snapshot.paramMap.get('lessonId')
    const module_slug_id = this.route.snapshot.paramMap.get('moduleId')
    const section_slug_id = this.route.snapshot.paramMap.get('sectionId')
    this.quizcmpdata = quizeId;
    const quizDetails = {
      id: quizeId,
      name: quizTitle,
      instruction: quizItemInstruction,
      course_slug: course_slug_id,
      lesson_slug: lesson_slug_id,
      module_slug: module_slug_id,
      section_slug: section_slug_id,
      is_completed: isCompleted,
      allow_attempts: '0',
      view_result_btn: view_btn,
      quiz_time: quiz_time,
      quiz_reference_type: quiz_ref_type,
      gradebook_section_type: gradebook_section_type,
      pageType: 3
    }
    this.container.clear();
    const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(QuizMultipleComponent);
    this.componentRef = this.container.createComponent(factory);

    this.componentRef.instance.type = quizDetails;
    this.componentRef.instance.output.subscribe(event => {
      if (event.action_type == 1) {
        // this.necessaryQuizId.
        console.log("quiz id after finish", event.quizId);

        const index = this.necessaryQuiz.findIndex(item => item.quiz_id == event.quizId);
        if (index > -1) {
          this.necessaryQuiz.splice(index, 1);
        }
        document.getElementById(event.quizId).classList.add('is-complete');
        const element = document.getElementById(event.quizId) as HTMLElement;
        const elementLesson = document.querySelectorAll('#quiz_lesson_' + event.quizId);
        // tslint:disable-next-line: no-shadowed-variable
        elementLesson.forEach(element => {
          this.renderer.addClass(element, 'active');
        });

      }
      if (event.action_type == 2) {
        this.getQuizStartById(event.quizId, event.quizTitle, event.instruction, null)
      }
      // setTimeout(() => {
      //   const quizElementLink: HTMLElement = document.getElementById(event.quizId) as HTMLElement;
      //   quizElementLink.click();
      // }, 200);
      this.lessonsQuizzes.forEach((element: any) => {
        if (event.quizId == element.quiz_id) {
          element.is_completed = event.is_completed;
          element.quiz_spent_time = event.quiz_spent_time;
          event.allSectionQuestionsCount;
          event.total;
        }
      });

      this.quizIdSubject.unsubscribe();
      // this.getLessonDetail(this.lessonId);
    });
  }






  openLinkFile(event, fileName) {
    const url: any = this.apiService.getApiSitePath() + 'uploads/media_images/' + fileName;
    window.location.href = url
    event.preventDefault()
  }
  /**
  * @description Images file extention
  * @date 2019-11-28
  * @method getCitationFile
  * @memberof LessonDetailComponent
  */
  getCitationFile(filename: any) {
    if (filename.split('.')[1] == "pdf") {
      this.imageFileEx = '<a href="' + this.getInstituteUrl + 'uploads/media_images/' + filename + '" class="f34 fw500" target="_blank"><i class="flaticon-pdf"></i></a>';
      return this.sanitizer.bypassSecurityTrustHtml(this.imageFileEx)
    }
    if (filename.split('.')[1] == "csv") {
      this.imageFileEx = '<a href="' + this.getInstituteUrl + 'uploads/media_images/' + filename + '" class="f34 fw500" target="_blank"><i class="newflaticon-csv"></i></a>';
      return this.sanitizer.bypassSecurityTrustHtml(this.imageFileEx)
    }
    if (filename.split('.')[1] == "doc") {
      this.imageFileEx = '<a href="' + this.getInstituteUrl + 'uploads/media_images/' + filename + '" class="f34 fw500" target="_blank"><i class="newflaticon-doc"></i></a>';
      return this.sanitizer.bypassSecurityTrustHtml(this.imageFileEx)
    }
    if (filename.split('.')[1] == "docx") {
      this.imageFileEx = '<a href="' + this.getInstituteUrl + 'uploads/media_images/' + filename + '" class="f34 fw500" target="_blank"><i class="newflaticon-file"></i><span class="d-block f11 fw600">DOCX</span></a>';
      return this.sanitizer.bypassSecurityTrustHtml(this.imageFileEx)
    }
    if (filename.split('.')[1] == "xls") {
      this.imageFileEx = '<a href="' + this.getInstituteUrl + 'uploads/media_images/' + filename + '" class="f34 fw500" target="_blank"><i class="newflaticon-xls"></i></a>';
      return this.sanitizer.bypassSecurityTrustHtml(this.imageFileEx)
    }
    if (filename.split('.')[1] == "xlsx") {
      this.imageFileEx = '<a href="' + this.getInstituteUrl + 'uploads/media_images/' + filename + '" class="f34 fw500" target="_blank"><i class="newflaticon-file"></i><span class="d-block f11 fw600">XLSX</span></a>';
      return this.sanitizer.bypassSecurityTrustHtml(this.imageFileEx)
    }
    if (filename.split('.')[1] == "mp4") {
      this.imageFileEx = '<a href="' + this.getInstituteUrl + 'uploads/media_images/' + filename + '" class="f34 fw500" target="_blank"><i class="newflaticon-mp4"></i></a>';
      return this.sanitizer.bypassSecurityTrustHtml(this.imageFileEx)
    }
    if (filename.split('.')[1] == "mp3") {
      this.imageFileEx = '<a href="' + this.getInstituteUrl + 'uploads/media_images/' + filename + '" class="f34 fw500" target="_blank"><i class="newflaticon-mp3"></i></a>';
      return this.sanitizer.bypassSecurityTrustHtml(this.imageFileEx)
    }
    if (filename.split('.')[1] == "ogg") {
      this.imageFileEx = '<a href="' + this.getInstituteUrl + 'uploads/media_images/' + filename + '" class="f34 fw500" target="_blank"><i class="newflaticon-file"></i><span class="d-block f11 fw600">OGG</span></a>';
      return this.sanitizer.bypassSecurityTrustHtml(this.imageFileEx)
    }
    if (filename.split('.')[1] == "jpeg" || filename.split('.')[1] == "jpg" || filename.split('.')[1] == "png" || filename.split('.')[1] == "gif") {
      this.imageFileEx = '<img src="' + this.getInstituteUrl + 'uploads/media_images/' + filename + '" class="img-fluid rounded">';
      return this.sanitizer.bypassSecurityTrustHtml(this.imageFileEx)
    }
    this.count++;

  }
  /**
  * @description Toggle section
  * @date 2019-11-13
  * @method togglePanel
  * @memberof LessonDetailComponent
  */
  togglePanel(togglePanelElement: HTMLElement, event) {
    let getElmH = togglePanelElement.children[0].clientHeight;

    if (this.showPanelStatus) {
      event.target.classList.toggle('active')
      togglePanelElement.classList.toggle('open');
      togglePanelElement.style.height = '0px';
      if (event.target.classList.contains('active')) {
        togglePanelElement.style.height = getElmH + 'px';
        if (togglePanelElement.id == 'imageCitationDataPanel') {
          this.renderer.setStyle(this.imageCitationDataPanel.nativeElement, 'height', 'auto')
        }
      } else {
        togglePanelElement.style.height = '0px';
      }
    } else {
      event.target.classList.toggle('active')
      togglePanelElement.classList.toggle('open');
      togglePanelElement.style.height = getElmH + 'px';
    }



  }
  /**
   * @description Reset toggle panel section
   * @date 2019-11-13
   * @method resetPanelCollapse
   * @memberof LessonDetailComponent
   */
  resetPanelCollapse() {

    if (this.showPanelStatus) {
      setTimeout(() => {
        const getHeaderPan = document.querySelectorAll('.pc-header.active');
        const getContentPan = document.querySelectorAll('.pc-content.open');
        for (let i = 0; i < getHeaderPan.length; i++) {
          this.renderer.removeClass(getHeaderPan[i], 'active');
          if (getContentPan[i] != undefined) {
            this.renderer.removeClass(getContentPan[i], 'open');
            this.renderer.setStyle(getContentPan[i], 'height', '0');
          }

        }
      }, 200)
    } else {
      setTimeout(() => {
        const getHeaderPan = document.querySelectorAll('.pc-header');
        const getContentPan = document.querySelectorAll('.pc-content');
        for (let i = 0; i < getHeaderPan.length; i++) {
          this.renderer.addClass(getHeaderPan[i], 'active');

          if (getContentPan[i] != undefined) {
            this.renderer.addClass(getContentPan[i], 'open');
            this.renderer.setStyle(getContentPan[i], 'height', 'auto');
          }

        }
      }, 200)
    }

  }

  citationLicenseLink(dataUrl: any, dataText: any) {
    if (dataUrl != '' && dataUrl != null) {
      this.citationLicense = '<a href="' + dataUrl + '" target="_blank">' + dataText + '</a>';
    } else {
      if (dataText != null) {
        this.citationLicense = '<span>' + dataText + '</span>';
      } else {
        this.citationLicense = '<span></span>';
      }

    }
    return this.sanitizer.bypassSecurityTrustHtml(this.citationLicense);
  }



  /**************************************/
  /****  Create Dynamic Component   */

  checkNextModule(completeCourseSlug, sectionSlug, nextModuleSlug, lessonId, moduleSlug) {
    this.nextBtnLoading = true;
    this.moduleSlug = this.route.snapshot.paramMap.get('moduleId')

    const moduleListPost = {
      course_slug: this.courseSlug,
      slug: this.moduleSlug,
      section_slug: this.sectionSlug,
      lesson_slug: this.lessonId,
      currentModule: this.moduleSlug,
      user_id: this.commonService.getUserId(),
      method: "getModuleBySlugWithRestrictions"
    }
    this.moduleService.getModuleList(moduleListPost).subscribe(res => {
      this.nextBtnLoading = false;
      // if (res['quiz_completion_status'] != undefined) {
      //   this.dialogParam = {
      //     redirect: false,
      //     message: res['message']
      //   }
      //   this.commonService.openCourseSectionExpireDialog(this.dialogParam)
      // } else {
      this.commonService.routeToModuleDetail(completeCourseSlug, sectionSlug, nextModuleSlug, lessonId, moduleSlug)
      //  }

    })
  }
}

