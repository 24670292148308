import {
  Component, OnInit, AfterViewInit, ViewChild, ViewContainerRef, ComponentRef,
  ComponentFactoryResolver, ComponentFactory, 
  OnDestroy, Renderer2
} from '@angular/core';
import { CourseService } from './../../service/course.service';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { DataService } from './../../service/data.service';
import { CommonService } from './../../service/common.service';
import { ApiService } from './../../service/api.service';
import { QuizMultipleComponent } from '../../component/quiz-multiple/quiz-multiple.component';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { CertificateQuizFormComponent } from './../../dialogBox/certificate-quiz-form/certificate-quiz-form.component';
import { MatDialog } from '@angular/material/dialog';
import { GeneralMessageComponent } from './../../dialogBox/general-message/general-message.component';
import { StudentTimeService } from './../../service/student-time.service';


@Component({
  selector: 'app-course-outcome',
  templateUrl: './course-outcome.component.html',
  styleUrls: ['./course-outcome.component.scss']
})
export class CourseOutcomeComponent implements OnInit {
  courseDetailPost: any;
  courseSlug: any;
  sectionSlug: any;
  sectionId: any;
  postData: any;
  courseDetail: any;
  breadcrumbCourseName;
  userData;
  userId;
  startModuleSlug;

  lessonSlug: any = null;

  isLoading: boolean = true;
  courseimg: any;
  courseId2: any;

  startModuleBtnStatus: boolean = false;
  sitePath: any;
  getCourseDocuments: any;
  getInstituteUrl: any;

  courseSectionEndDate: any;
  courseQuizzes: any;

  quizPanelStatus: boolean = false;


  quizContainer: boolean = false;



  quizcmpdata: any;


  courseDescriptionWords: any;
  readMoreStatus: any;
  launchURL: any;
  quizIdSubject: any;
  quizSubjectData: any;
  charLimit: number = 270;

  // codeApproved: boolean = false;
  codeApproved: {} = {};

  /************************************** */
  @ViewChild("quizeDynamicComponent", { read: ViewContainerRef, static: true }) container;
  componentRef: ComponentRef<any>;

  showPanelStatus: boolean;
  courseCompPostData: { method: string; id: any; course_slug: any; section_slug: any; };
  courseCompletionValue: any;
  dialogRef: any;
  certificateCode: any;
  panelBgCol: any;
  panelTxtCol: any;
  seconds: number;
  timer: any;
  timestamp: string;
  oneTime: boolean = false;
  certQuizId: any;

  currentBtnThemeContainer: Subscription;
  buttonTheme: any;

  constructor(private courseService: CourseService, private router: Router, private route: ActivatedRoute,
    private dataService: DataService, public commonService: CommonService, private apiService: ApiService,
    private resolver: ComponentFactoryResolver, 
    private renderer: Renderer2, private sanitizer: DomSanitizer, private dialog: MatDialog, private studentTimeService: StudentTimeService) {
    this.currentBtnThemeContainer = this.dataService.currentButtonTheme.subscribe(message => {
      this.buttonTheme = message;
    }, error => error);
    this.courseSlug = this.route.snapshot.paramMap.get('courseId');
    this.sectionSlug = this.route.snapshot.paramMap.get('sectionId');
    this.quizIdSubject = this.commonService.getQuizIdSubject.subscribe(
      data => {
        if (data['quiz_id']) {
          if (data['level_type'] == 1 && data['quiz_id']['quiz_reference_type'] != '7') {
            this.quizSubjectData = data['quiz_id'];
            this.getQuizStartById(this.quizSubjectData['id'], this.quizSubjectData['title'], this.quizSubjectData['instruction'], null, null, null, null, this.quizSubjectData.quiz_reference_type, this.quizSubjectData.gradebook_section_type)
          }
        }
      }
    )
  }

  toggleContainer(event) {
    event.target.parentElement.previousElementSibling.classList.toggle('open');
    const parentElm = event.target.parentElement.previousElementSibling;
    if (parentElm.className === 'course-description-container open') {
      event.target.innerText = 'Less';
      this.charLimit = 5000000;
    } else {
      event.target.innerText = 'Read More';
      this.charLimit = 270;
    }

  }

  getPanelVisuals() {
    const siteDetail = this.commonService.getCurrentSiteDetail();
    this.panelBgCol = siteDetail.theme_style.panel_back_color;
    this.panelTxtCol = siteDetail.theme_style.panel_text_color;
  }

  startTimer() {
    this.seconds = 0;
    this.timer = setInterval(() => {
      const value = Math.abs(this.seconds);
      const days = Math.floor(value / 1440);
      const hours = Math.floor((value - (days * 1440)) / 3600);
      const min = Math.floor((value - (days * 1440) - (hours * 3600)) / 60);
      const sec = value - (days * 1440) - (hours * 3600) - (min * 60);
      // tslint:disable-next-line: max-line-length

      // tslint:disable-next-line: max-line-length
      this.timestamp = `${this.seconds < 0 ? '-' : ''}${days > 0 ? days + '.' : ''}${hours < 10 ? '0' + hours : hours}:${min < 10 ? '0' + min : min}:${sec < 10 ? '0' + sec : sec}`
      this.seconds++;
    }, 1000);
  }

  startCourse() {
    const data = {
      id: this.commonService.getUserId(),
      method: 'savingStudentTime',
      container_id: this.commonService.getDomainContainerId(),
      course_slug: this.courseSlug,
      section_slug: this.sectionSlug,
      course_time: this.timestamp,
      page: 'C'
    };

    this.studentTimeService.saveStudentTime(data).subscribe(res => {
    });

    clearInterval(this.timer);
    //  this.commonService.routeToModuleDetail(this.courseSlug , this.sectionSlug ,  this.startModuleSlug , this.lessonSlug);
  }



  ngOnInit() {
    this.startTimer();
    this.getPanelVisuals();
    this.getInstituteUrl = this.apiService.getApiSitePath()
    this.sitePath = this.apiService.getSiteFolderPath();
    this.getCourseDetail()
    const moduleListPostData = {
      id: this.commonService.getUserId(),
      method: 'getModuleListByCourseSlug',
      course_slug: this.courseSlug
    };

    this.courseService.getModuleListByCourse(moduleListPostData).subscribe(res => {
      if (!res['jwt_status']) {
        this.commonService.redirectToLogin()
      } else {
        if (res['data'] != undefined) {
          this.startModuleSlug = res['data'].slug
          this.startModuleBtnStatus = true;
        }

      }
    })

  }

  openCertificateQuizDialog(quizCode: any, qId : any) {
    this.dialogRef = this.dialog.open(CertificateQuizFormComponent, {
      data: {
        code: quizCode,
        qId: qId
      }
    });
    this.dialogRef.afterClosed().subscribe(res => {
      
      let msg: any;
      if (res.event == 1) {
        if(localStorage.getItem('locale') == 'ger'){
          msg = 'Code erfolgreich aktiviert Bitte klicke jetzt auf "Quiz starten".';
        }else{
          msg = 'Code successfully activated. Please click on "Start quiz"';
        }
        this.codeApproved[qId] = true;
      } else {
        if(localStorage.getItem('locale') == 'ger'){
          msg = 'Code nicht gültig für dieses Quiz.';
        }else{
          msg = 'Code not valid for this quiz.';
        }
      }
      this.openGeneralDialogBox(msg);
    });
  }

  openGeneralDialogBox(msg: any) {
    this.dialogRef = this.dialog.open(GeneralMessageComponent, {
      data: {
        message: msg
      }
    });
    this.dialogRef.afterClosed().subscribe(res => {
    });
  }


  


  // tslint:disable-next-line: use-life-cycle-interface
  ngAfterViewInit() {
    if (this.quizSubjectData) {
      if (this.quizSubjectData.quiz_reference_type != '7') {
        setTimeout(() => {
          // tslint:disable-next-line: max-line-length
          this.getQuizStartById(this.quizSubjectData['id'], this.quizSubjectData['title'], this.quizSubjectData['instruction'], null, null, null, null, this.quizSubjectData.quiz_reference_type, this.quizSubjectData.gradebook_section_type)
        }, 1000);
      }
    }
  }
  /**
   * @description Toggle section
   * @date 2019-11-13
   * @method togglePanel
   * @memberof CourseDetailsComponent
   */
  togglePanel(togglePanelElement: HTMLElement, event) {

    const getElmH = togglePanelElement.children[0].clientHeight;
    // if (this.showPanelStatus) {
    //   event.target.classList.toggle('active')
    //   togglePanelElement.classList.toggle('open');
    //   togglePanelElement.style.height = '0px';
    //   if (event.target.classList.contains('active')) {
    //     togglePanelElement.style.height = getElmH + 'px';
    //   } else {
    //     togglePanelElement.style.height = '0px';
    //   }
    // } else {
    //   event.target.classList.toggle('active')
    //   togglePanelElement.classList.toggle('open');
    //   togglePanelElement.style.height = getElmH + 'px';
    // }


    event.target.classList.toggle('active')
    togglePanelElement.classList.toggle('open');
    togglePanelElement.style.height = getElmH + 'px';

  }
  /**
   * @description Reset toggle panel section
   * @date 2019-11-13
   * @method resetPanelCollapse
   * @memberof CourseDetailsComponent
   */
  resetPanelCollapse() {
    // if (this.showPanelStatus) {
    //   setTimeout(() => {
    //     const getHeaderPan = document.querySelectorAll('.pc-header.active');
    //     const getContentPan = document.querySelectorAll('.pc-content.open');
    //     for (let i = 0; i < getHeaderPan.length; i++) {
    //       this.renderer.removeClass(getHeaderPan[i], 'active');

    //       if(getContentPan[i]){
    //         this.renderer.removeClass(getContentPan[i], 'open');
    //         this.renderer.setStyle(getContentPan[i], 'height', '0');
    //       }

    //     }
    //   }, 200)
    // } else {
    //   setTimeout(() => {
    //     const getHeaderPan = document.querySelectorAll('.pc-header');
    //     const getContentPan = document.querySelectorAll('.pc-content');
    //     for (let i = 0; i < getHeaderPan.length; i++) {
    //       this.renderer.addClass(getHeaderPan[i], 'active');
    //       this.renderer.addClass(getContentPan[i], 'open');
    //       this.renderer.setStyle(getContentPan[i], 'height', 'auto');
    //     }
    //   }, 200)
    // }


    setTimeout(() => {
      const getHeaderPan = document.querySelectorAll('.pc-header');
      const getContentPan = document.querySelectorAll('.pc-content');
      for (let i = 0; i < getHeaderPan.length; i++) {
        this.renderer.addClass(getHeaderPan[i], 'active');
        this.renderer.addClass(getContentPan[i], 'open');
        this.renderer.setStyle(getContentPan[i], 'height', 'auto');
      }
    }, 200);



  }




  /**
   * @description get course details by course / course-section slug
   * @date 2019-09-16
   * @memberof CourseDetailsComponent
   */
  getCourseDetail() {
    this.postData = {
      id: this.commonService.getUserId(),
      courseSlug: this.courseSlug,
      sectionSlug: this.sectionSlug,
      user_id: this.commonService.getUserId(),
      method: 'courseDetailsBySlugs'
    }

    this.courseService.getCourseDetail(this.postData).subscribe(res => {
      if (!res['jwt_status']) {
        this.commonService.openSessionExpireDialog()
        return
      } else {
        if (res['status']) {
          this.courseDetail = res['data'];
          if (this.courseDetail != undefined) {

            this.showPanelStatus = (this.courseDetail['openAllPanel'] == 1) ? false : true

            this.resetPanelCollapse()
            //read more
            this.courseDescriptionWords = this.courseDetail.description.split('').length;
            if (this.courseDescriptionWords > 270) {
              this.readMoreStatus = true;
            }

            const newArray = res['data'].quiz_data.filter(item => item.is_cert_quiz == 1);
            if (res['data'].quiz_data.length > 0) {
              if (newArray.length > 0) {
                if (res['data'].courseProgress == 100) {
                  this.quizPanelStatus = true;
                  this.courseQuizzes = newArray;
                  

                  if(this.courseQuizzes?.length > 0){
                    for (let val of this.courseQuizzes) {

                      if(this.certificateCode == '' || this.certificateCode == null || this.certificateCode == undefined){
                        this.codeApproved[val.quiz_id] = true;
                      }else{
                        this.codeApproved[val.quiz_id] = false;
                      }
                      /*if(this.certificateCode !== ''){
                        this.codeApproved[val.quiz_id] = false;
                      }else{
                        this.codeApproved[val.quiz_id] = true;
                      }*/


                    }
                  }
                  
                }
              }
              const selectedIndex = res['data'].quiz_data.findIndex(item => (item.is_cert_quiz == 1));
              if(selectedIndex != -1){
                this.certQuizId = res['data'].quiz_data[selectedIndex].quiz_id;
                this.certificateCode = res['data'].quiz_data[selectedIndex].certificate_code;
                this.oneTime = (res['data'].quiz_data[selectedIndex].is_one_time == 1) ? true : false;
                //this.codeApproved = (this.certificateCode !== '') ? false : true;
              }

            } else {
              this.quizPanelStatus = false;
            }
            this.getCourseDocuments = res['data']['documents']
            this.sectionId = this.courseDetail.section_id
            this.courseimg = this.courseDetail.course_img != 'undefined' ? this.courseDetail.course_img : null;
            this.isLoading = false;
          }
          if (this.componentRef != undefined) {
            this.componentRef.destroy()
          }
        } else {

          this.commonService.redirectToLogin()

        }

      }
    }, error => error)
  }



  /**************************************/
  /****  Create Dynamic Component   */

  // tslint:disable-next-line: max-line-length
  getQuizStartById(quizeId: any, quizTitle: string, quizItemInstruction: any, isCompleted: null, attempts?: null, view_btn?: null, quiz_time?: null, quiz_ref_type?: any, gradebook_section_type?: any) {

    const course_slug_id = this.route.snapshot.paramMap.get('courseId')
    const section_slug_id = this.route.snapshot.paramMap.get('sectionId')
    this.quizcmpdata = quizeId;
    let quizCode : any ; 
    this.courseQuizzes.forEach(element => {
      if(element.quiz_id == quizeId){
        quizCode = element.certificate_code ; 
      }   
    });
    
    const quizDetails = {
      id: quizeId,
      name: quizTitle,
      instruction: quizItemInstruction,
      course_slug: course_slug_id,
      section_slug: section_slug_id,
      is_completed: isCompleted,
      allow_attempts: attempts,
      view_result_btn: view_btn,
      quiz_time: quiz_time,
      quiz_reference_type: quiz_ref_type,
      gradebook_section_type: gradebook_section_type,
      pageType: 1,
      quizCode : quizCode
    }

    if (this.container) {
      this.container.clear();
      const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(QuizMultipleComponent);
      this.componentRef = this.container.createComponent(factory);
      this.componentRef.instance.type = quizDetails;
      this.componentRef.instance.output.subscribe(event => {

        if (event.action_type == 1) {
          document.getElementById(event.quizId).classList.add('is-complete');
          const element = document.getElementById(event.quizId) as HTMLElement;
          const elementCourse = document.getElementById('quiz_course_' + event.quizId) as HTMLElement;

          if (elementCourse != null) {
            this.renderer.addClass(elementCourse, 'active');
          }
        }
        if (event.action_type == 2) {
          this.getQuizStartById(event.quizeId, event.quizTitle, event.instruction, null);
        }
        
        this.courseQuizzes.forEach((element:any) => {
          if(event.quizId == element.quiz_id){
            element.is_completed = event.is_completed;
            element.quiz_spent_time = event.quiz_spent_time;
            event.allSectionQuestionsCount;
            event.total;
            element.quiz_attempt_allow = event.quiz_attemp;
          }
          console.log(event.quiz_attemp);
          
      });


        this.quizIdSubject.unsubscribe();
      });
    }

  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnDestroy() {
    this.currentBtnThemeContainer.unsubscribe();
  }


}
